import { useParams } from 'react-router-dom';
import { useAppSelector } from '../../hooks';
import { selectClientId } from '../../data-access/client-slice';
import { useGetProjectsQuery } from '../../services/projects-api';

import Header from '../../ui/header/header';
import Footer from '../../ui/footer/footer';
import CardProduct from '../../ui/card-product/card-product';
import ButtonAction from '../../ui/button-action/button-action';
import ButtonBack from '../../ui/button-back/button-back';
import ListingProjects from '../../ui/listing-projects/listing-projects';
import Loader from '../../ui/loader/loader';

import './my-projects.scss';

const MyProjects = () => {
  const {clientid} = useParams();
  const clientId = useAppSelector(selectClientId);
  const {
    data: projects,
    isLoading: projectsIsLoading,
    isFetching: projectsIsFetching,
    isSuccess: projectsIsSuccess,
  } = useGetProjectsQuery(Number(clientid));

  return (
    <>
      <Header/>
      <div className="my-projects-page">
        <ButtonBack to={`/${clientid}`} title="Back to Dashboard"/>
        <div className="container">
          <h1>My projects</h1>
          {projectsIsLoading || projectsIsFetching ? <Loader/> :
            (projectsIsSuccess && projects.data && projects.data.length) ?
              <ListingProjects>
                {projects.data.map((project) =>
                  <div className="card-wrapper" key={project.id}>
                    <CardProduct
                      title={project.name}
                      button={<ButtonAction buttonType="link" primary outline light
                                            to={`/${clientId}/projects/${project.id}`}>View
                        project</ButtonAction>}
                    />
                  </div>
                )}
                <div className="card-wrapper">
                  <CardProduct
                    title="Want to start something new?"
                    button={<ButtonAction buttonType="link" primary outline to={`/${clientId}/new-project`}>Start
                      project</ButtonAction>}
                    light
                  />
                </div>
              </ListingProjects> : null}
        </div>
      </div>
      <Footer/>
    </>
  );
};

export default MyProjects;