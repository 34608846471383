// @ts-ignore
import { createApi } from '@reduxjs/toolkit/dist/query/react';
// @ts-ignore
import baseQueryWithErrorCheck from './base-query';

export const pageApi = createApi({
  reducerPath: "pageApi",
  baseQuery: baseQueryWithErrorCheck,
  endpoints: (builder) => ({
    getPage: builder.query({
      query: ({clientId, projectId, pageId}: {clientId: number, projectId: number, pageId: number}) => {
        return {
          url: `/${clientId}/projects/${projectId}/pages/${pageId}`,
          method: "get"
        }
      }
    }),
  })
})

export const { useGetPageQuery } = pageApi;