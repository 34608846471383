import {ReactNode} from 'react';
import "./listing-projects.scss";

export interface ListingProjectsProps {
  children: ReactNode;
}

const ListingProjects = ({children}: ListingProjectsProps) => {
  return (
    <div className="list-projects">
      {children}
    </div>
  );
};

export default ListingProjects;