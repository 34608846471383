import React, { useEffect, useState } from 'react';
import { useNavigate, useParams } from "react-router-dom";
import { Form, Formik } from 'formik';
import * as Yup from 'yup';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faReply } from "@fortawesome/free-solid-svg-icons";
import {
  useGetTicketCommentsQuery,
  useGetTicketQuery,
  usePostTicketCommentMutation,
} from '../../services/ticket-api';

import Loader from '../../ui/loader/loader';
import Header from "../../ui/header/header";
import ButtonBack from "../../ui/button-back/button-back";
import Footer from "../../ui/footer/footer";
import UserProfile from "../../ui/user-profile/user-profile";
import TicketComment from '../../ui/ticket-comment/ticket-comment';
import Card from '../../ui/card/card';
import ButtonAction from "../../ui/button-action/button-action";
import ButtonWithIcon from '../../ui/button-with-icon/button-with-icon';
import InputTextarea from '../../ui/input-textarea/input-textarea';
import FilesUpload from '../../ui/files-upload/files-upload';
import TicketDetails from './ui/ticket-details/ticket-details';

import "./ticket.scss";
import { setMessage } from '../../data-access/message-slice';
import { useAppDispatch } from '../../hooks';
import { firstLettersInName } from '../../utils/helpers';

const Ticket = () => {
  const [filesCommentBlob, setFilesCommentBlob] = useState<FileList | null | Array<any>>(null);
  const [resetFiles, setResetFiles] = useState<boolean>(false);
  const [showCommentForm, setShowCommentForm] = useState<boolean>(false);
  const dispatch = useAppDispatch();
  let {id} = useParams();
  let navigate = useNavigate();

  const {
    data: ticket,
    isFetching: ticketIsFetching,
    isLoading: ticketIsLoading,
    isSuccess: ticketIsSuccess,
  } = useGetTicketQuery(Number(id), {refetchOnMountOrArgChange: true});

  const {
    data: ticketComments,
    isFetching: ticketCommentsIsFetching,
    isLoading: ticketCommentsIsLoading,
    isSuccess: ticketCommentsIsSuccess,
    refetch: refetchTicketComments
  } = useGetTicketCommentsQuery(Number(id));

  const [newTicketComment, {
    isSuccess: isNewTicketCommentSuccess,
    isLoading: isNewTicketCommentLoading,
  }] = usePostTicketCommentMutation();

  useEffect(() => {
    if (isNewTicketCommentSuccess) {
      dispatch(setMessage({
        content: "Comment added successfully.",
        type: "success"
      }));
    }
  }, [isNewTicketCommentSuccess]);

  const renderComments = () => (
    ticketComments.data.map((comment: any) => (
      <TicketComment
        key={comment.id}
        id={comment.id}
        // profileIcon={comment.user.profile_photo_path}
        initials={firstLettersInName(comment.user.name)}
        date={new Date(comment.created_at)}
        ticketId={Number(id)}
        comment={<div>
          {comment.body}
          {comment.attachments.length ?
            <div className="files">
              {
                comment.attachments.map((file: any, index: number) =>
                  <p key={index} >
                    <a href={file.url} target="_blank" rel="noreferrer" className="file-name">
                      <svg aria-hidden="true" viewBox="0 0 24 24">
                        <path d="M16.5 6v11.5c0 2.21-1.79 4-4 4s-4-1.79-4-4V5c0-1.38 1.12-2.5 2.5-2.5s2.5 1.12 2.5 2.5v10.5c0 .55-.45 1-1 1s-1-.45-1-1V6H10v9.5c0 1.38 1.12 2.5 2.5 2.5s2.5-1.12 2.5-2.5V5c0-2.21-1.79-4-4-4S7 2.79 7 5v12.5c0 3.04 2.46 5.5 5.5 5.5s5.5-2.46 5.5-5.5V6h-1.5z"></path>
                      </svg>
                      {file.name}
                    </a>
                  </p>
                )
              }
            </div> : null}
        </div>}
        reply={() => refetchTicketComments()}>
        {comment.replies.length ?
          <div className="replies">
            {comment.replies.map((reply: any) => (
              <TicketComment
                key={reply.id}
                id={reply.id}
                // profileIcon={reply.user.profile_photo_path}
                initials={firstLettersInName(reply.user.name)}
                date={new Date(reply.created_at)}
                ticketId={Number(id)}
                comment={<div>
                  {reply.body}
                  {reply.attachments.length ?
                    <div className="files">
                      {
                        reply.attachments.map((file: any, index: number) =>
                          <p key={index}>
                            <a href={file.url} target="_blank" rel="noreferrer" className="file-name">
                              <svg aria-hidden="true" viewBox="0 0 24 24">
                                <path
                                  d="M16.5 6v11.5c0 2.21-1.79 4-4 4s-4-1.79-4-4V5c0-1.38 1.12-2.5 2.5-2.5s2.5 1.12 2.5 2.5v10.5c0 .55-.45 1-1 1s-1-.45-1-1V6H10v9.5c0 1.38 1.12 2.5 2.5 2.5s2.5-1.12 2.5-2.5V5c0-2.21-1.79-4-4-4S7 2.79 7 5v12.5c0 3.04 2.46 5.5 5.5 5.5s5.5-2.46 5.5-5.5V6h-1.5z"></path>
                              </svg>
                              {file.name}
                            </a>
                          </p>,
                        )
                      }
                    </div> : null}
                </div>}/>
                ))}
          </div> : null}
      </TicketComment>
    ))
  )

  return (
    <>
      <Header/>
      <ButtonBack onClick={() => navigate(-1)} to="" title="Back to Product"/>
      {ticketIsFetching || ticketIsLoading ? <Loader/> :
        (ticketIsSuccess && ticket.data) ?
        <div className="ticket-page">
          <div className="container">
            <div className="ticket-container">
              <div className="ticket-content">
                <div className="ticket-details">
                  <h1 className="title-page">
                    {ticket.data.title}
                  </h1>
                </div>
                <div className="comments-container">
                  <div>
                    <UserProfile
                      initials={firstLettersInName(ticket.data.opener_name)}
                      email={ticket.data.opener_email}
                      date={new Date(ticket.data.open_date)}
                      showTime
                    />
                    <Card>
                      <p>{ticket.data.description}</p>
                      {ticket.data.attachments.length ?
                        <div className="files files-wrapper">
                          {
                            ticket.data.attachments.map((file: any, index: number) =>
                              <p key={index} >
                                <a href={file.url} target="_blank" rel="noreferrer" className="file-name">
                                  <svg aria-hidden="true" viewBox="0 0 24 24">
                                    <path d="M16.5 6v11.5c0 2.21-1.79 4-4 4s-4-1.79-4-4V5c0-1.38 1.12-2.5 2.5-2.5s2.5 1.12 2.5 2.5v10.5c0 .55-.45 1-1 1s-1-.45-1-1V6H10v9.5c0 1.38 1.12 2.5 2.5 2.5s2.5-1.12 2.5-2.5V5c0-2.21-1.79-4-4-4S7 2.79 7 5v12.5c0 3.04 2.46 5.5 5.5 5.5s5.5-2.46 5.5-5.5V6h-1.5z"></path>
                                  </svg>
                                  {file.name}
                                </a>
                              </p>
                            )
                          }
                        </div> : null}
                    </Card>
                  </div>

                  {ticketCommentsIsFetching || ticketCommentsIsLoading ? <Loader/> :
                    (ticketCommentsIsSuccess && ticketComments.data && ticketComments.data.length > 0)
                      ? renderComments() : null
                  }
                </div>
                <div className="reply-button">
                  <ButtonWithIcon
                    title="Reply"
                    icon={<FontAwesomeIcon icon={faReply}/>}
                    action={() => setShowCommentForm(!showCommentForm)}/>
                </div>

                <Formik
                  initialValues={{
                    comment: ''
                  }}
                  validationSchema={
                    Yup.object({
                      comment: Yup.string().required('This field is required'),
                    })
                  }
                  onSubmit={async (values, { setSubmitting, resetForm }) => {
                    try {
                      const ticketObject: any = {
                        body: values.comment,
                        attachments: filesCommentBlob,
                      }

                      const formData = new FormData();
                      Object.keys(ticketObject).forEach(key => {
                        if (key === 'attachments' && ticketObject.attachments) {
                          ticketObject[key].forEach((file: any) => formData.append("attachments[]", file));
                        } else {
                          formData.append(key, ticketObject[key]);
                        }
                      });

                      await newTicketComment({ticketId: Number(id), data: formData})
                        .unwrap()
                        .then(() => {
                          setSubmitting(false);
                          resetForm();
                          setResetFiles(true);
                          refetchTicketComments();
                          setShowCommentForm(false);
                        })
                    } catch (error: any) {
                      console.error('Failed to save the post: ', error)
                      setSubmitting(false);
                    }
                  }}>
                  {({ isSubmitting }) => (
                    <Form autoComplete="off" className={`form-comment ${showCommentForm ? 'show' : ''}`}>
                      <div className="input-wrapper">
                        <InputTextarea
                          label="Comment"
                          name="comment"
                          id="comment"
                        />
                      </div>
                      <div className="input-wrapper">
                        <FilesUpload
                          id={ticket.data.id}
                          label="Files"
                          reset={resetFiles}
                          uploadFilesHandler={(files: FileList | null | Array<any>) => setFilesCommentBlob(files) }/>
                      </div>
                      <ButtonAction
                        buttonType="button"
                        type="submit"
                        primary
                        disabled={isSubmitting || isNewTicketCommentLoading}>
                        Post comment
                      </ButtonAction>
                    </Form>
                    )}
                </Formik>
              </div>

              <div className="ticket-info">
                <TicketDetails data={ticket.data} />
              </div>
            </div>

          </div>
        </div> : null
      }
      <Footer/>
    </>
  );
};

export default Ticket;
