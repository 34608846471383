import { useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { SwiperSlide } from 'swiper/react';

import { useAppSelector } from '../../hooks';
import { useGetProjectsQuery } from '../../services/projects-api';
import {
  selectClientOnboardingLevelTooSmall,
  selectUser,
  selectUserOnboardingLevelTooSmall,
} from '../../data-access/auth-slice';
import {selectClientId, selectClient} from '../../data-access/client-slice';

import Header from '../../ui/header/header';
import Footer from '../../ui/footer/footer';
import ButtonAction from '../../ui/button-action/button-action';
import CardProduct from '../../ui/card-product/card-product';
import CardsList from '../../ui/cards-list/cards-list';
import SwiperCards from '../../ui/swiper-cards/swiper-cards';
import SuperchargeProjects from '../../ui/supercharge-projects/supercharge-projects';
import Loader from '../../ui/loader/loader';

import './dashboard.scss';
import 'swiper/css/navigation';

const Dashboard = () => {
  const user = useAppSelector(selectUser);
  const client = useAppSelector(selectClient);
  const clientId = useAppSelector(selectClientId);
  const added_extras = client?.added_extras;
  const news = client?.posts;

  const navigate = useNavigate();
  const {clientid} = useParams();

  const clientOnboardingLevelTooSmall = useAppSelector(selectClientOnboardingLevelTooSmall);
  const userOnboardingLevelTooSmall = useAppSelector(selectUserOnboardingLevelTooSmall);

  const {
    data: projects,
    isLoading: projectsIsLoading,
    isFetching: projectsIsFetching,
    isSuccess: projectsIsSuccess,
  } = useGetProjectsQuery(Number(clientId), {skip: !(!!clientId) || clientOnboardingLevelTooSmall || userOnboardingLevelTooSmall});

  useEffect(() => {
    if (clientid && Number(clientid) === clientId) {
      navigate(`/${clientId}`, {replace: true});
      // refetchClient();
    }
  }, [clientId, clientid]);

  const welcomeMessage = () => {
    let message: string;
    const today = new Date()
    const currentHour = today.getHours()

    if (currentHour < 12) {
      message = "Good morning";
    } else if (currentHour < 18) {
      message = "Good afternoon"
    } else {
      message = "Good evening"
    }
    return message;
  }

  const renderProjects = () => {
    return projects?.data.map((project) => {
      return (
        <SwiperSlide key={project.id}>
          <CardProduct
            title={project.name}
            button={<ButtonAction buttonType="link" primary outline light
                                  to={`/${clientid}/projects/${project.id}`}>View
              project</ButtonAction>}
          />
        </SwiperSlide>
      )
    })
  }

  const renderNews = () => {
    if (news && news.length) {
      return news.map((post, index: number) => {
        return (
          <SwiperSlide key={`post-${index}`}>
            <CardProduct
              image={post.image}
              title={post.title}
              description={post.description}
              button={<ButtonAction buttonType="external_link" primary outline light href={post.external_url} target="_blank" rel="noreferrer">{post.button_label}</ButtonAction>}
            />
          </SwiperSlide>
        )
      })
    }
  }

  return (
      <>
        <Header/>
        <div className="dashboard-page">
          {
            clientid && clientId ?
              <div className="hero-page">
                <div className="container">
                  <div className="d-flex align-items-center">
                    <h1>{welcomeMessage()}, {user?.name}</h1>
                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512" fill="#fff">
                      <path
                        d="M256 352C293.2 352 319.2 334.5 334.4 318.1C343.3 308.4 358.5 307.7 368.3 316.7C378 325.7 378.6 340.9 369.6 350.6C347.7 374.5 309.7 400 256 400C202.3 400 164.3 374.5 142.4 350.6C133.4 340.9 133.1 325.7 143.7 316.7C153.5 307.7 168.7 308.4 177.6 318.1C192.8 334.5 218.8 352 256 352zM208.4 208C208.4 225.7 194 240 176.4 240C158.7 240 144.4 225.7 144.4 208C144.4 190.3 158.7 176 176.4 176C194 176 208.4 190.3 208.4 208zM304.4 208C304.4 190.3 318.7 176 336.4 176C354 176 368.4 190.3 368.4 208C368.4 225.7 354 240 336.4 240C318.7 240 304.4 225.7 304.4 208zM512 256C512 397.4 397.4 512 256 512C114.6 512 0 397.4 0 256C0 114.6 114.6 0 256 0C397.4 0 512 114.6 512 256zM256 48C141.1 48 48 141.1 48 256C48 370.9 141.1 464 256 464C370.9 464 464 370.9 464 256C464 141.1 370.9 48 256 48z"/>
                    </svg>
                  </div>
                </div>
              </div> : null
          }
          {
            user && user.clients.length === 0 ?
              <div className="container">
                <h2 className="empty">No data found.</h2>
              </div> : null
          }
          <div className="projects-section">
            <div className="container">
              {
                projectsIsLoading || projectsIsFetching ?
                  <Loader/> :
                  <>
                    {
                      (projectsIsSuccess && projects.data && projects.data.length) ?
                        <CardsList
                          title="My projects"
                          button={projects?.data.length > 0 ? <ButtonAction buttonType="link" primary outline to={`/${clientid}/my-projects`}>Show all projects</ButtonAction> : null}
                        >
                          {(projectsIsSuccess && projects?.data.length === 0) ?
                            <h4>No project found.</h4> :
                            (projectsIsSuccess && projects.data && projects.data.length) ?
                              <div className="cards-container">
                                <SwiperCards
                                  spaceBetween={15}
                                  slidesPerView={1}
                                  breakpoints={{
                                    576: {
                                      slidesPerView: 1,
                                    },
                                    992: {
                                      slidesPerView: 2,
                                    },
                                    1200: {
                                      slidesPerView: 3,
                                    },
                                    1600: {
                                      slidesPerView: 4,
                                    },
                                  }}
                                >
                                  {renderProjects()}
                                </SwiperCards>
                                <div className="card-cta">
                                  <CardProduct
                                    title="Want to start something new?"
                                    button={<ButtonAction buttonType="link" primary outline to={`/${clientid}/new-project`}>Start project</ButtonAction>}
                                    light
                                  />
                                </div>
                              </div> : null}
                        </CardsList>
                        : null
                    }
                    {
                      (added_extras && added_extras.length) ? <SuperchargeProjects added_extras={added_extras} /> : null
                    }

                    {(news && news.length) ?
                      <CardsList
                        title="Latest from Jump"
                        button={<ButtonAction buttonType="link" primary outline to={`/${clientid}/my-news`}>Show all news</ButtonAction>}>
                        <SwiperCards
                          spaceBetween={15}
                          slidesPerView={1}
                          breakpoints={{
                            576: {
                              slidesPerView: 2,
                            },
                            992: {
                              slidesPerView: 3,
                            },
                          }}
                        >
                          {renderNews()}
                        </SwiperCards>
                      </CardsList>
                      : null
                    }
                  </>
              }
            </div>
          </div>
        </div>
        <Footer/>
      </>
  );
};

export default Dashboard;