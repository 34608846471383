import { ReactNode } from 'react';
import { Swiper } from 'swiper/react';
import { A11y, Navigation } from 'swiper';

import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';
import 'swiper/css/scrollbar';

import './swiper-cards.scss';

export interface SwiperCardsProps {
    children: ReactNode;
    [k: string]: any;
}

const SwiperCards = ({children, ...restProps}: SwiperCardsProps) => {
    return (
        <div className="swiper-cards">
            <Swiper
                modules={[Navigation, A11y]}
                navigation
                {...restProps}
            >
                {children}
            </Swiper>
        </div>
    );
};

export default SwiperCards;