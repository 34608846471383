import { useEffect } from 'react';
import { Route, Routes } from 'react-router-dom';
import { toast } from "react-toastify";
import { useAppDispatch, useAppSelector } from './hooks';
import { useLazyGetUserQuery } from './services/auth-api';
import { useLazyGetQuotesQuery } from './services/quotes-api';
import { useGetProjectsQuery } from './services/projects-api';
import { selectClientId, setProjects } from './data-access/client-slice';
import {
    selectClientOnboardingLevelTooSmall,
    selectToken,
    selectUserOnboardingLevelTooSmall,
} from './data-access/auth-slice';
import { selectMessage, clearMessage } from './data-access/message-slice';

import AuthGuard from './guards/AuthGuard';
import DashboardGuard from "./guards/DashboardGuard";
import Dashboard from './feature/dashboard/dashboard';
import Product from './feature/product/product';
import NewProject from './feature/new-project/new-project';
import MyProjects from './feature/my-projects/my-projects';
import MyNews from './feature/my-news/my-news';
import ProjectPage from './feature/project-page/project-page';
import Quotes from './feature/quotes/quotes';
import Quote from './feature/quote/quote';
import NewTicket from './feature/new-ticket/new-ticket';
import Ticket from "./feature/ticket/ticket";
import Notifications from './feature/notifications/notifications';
import Login from './feature/auth/login/login';
import ForgotPassword from './feature/auth/forgot-password/forgot-password';
import ResetPassword from "./feature/auth/reset-password/reset-password";
import Invite from "./feature/auth/invite/invite";
import Footer from './ui/footer/footer';
import ModalOnboardingClient from "./feature/modal-onboarding-client/ModalOnboardingClient";
import ModalOnboardingUser from "./feature/modal-onboarding-user/ModalOnboardingUser";

import "react-toastify/dist/ReactToastify.css";
import './app.scss';
import './ui/auth.scss';

const App = () => {
    const dispatch = useAppDispatch();
    const token = useAppSelector(selectToken);
    const clientId = useAppSelector(selectClientId);
    const message = useAppSelector(selectMessage);
    const clientOnboardingLevelTooSmall = useAppSelector(selectClientOnboardingLevelTooSmall);
    const userOnboardingLevelTooSmall = useAppSelector(selectUserOnboardingLevelTooSmall);

    const [getUser] = useLazyGetUserQuery();

    const [getQuotes] = useLazyGetQuotesQuery();

    const {data: projects,} = useGetProjectsQuery(Number(clientId), {skip: !(!!clientId) || clientOnboardingLevelTooSmall || userOnboardingLevelTooSmall});


    // if (clientOnboardingLevelTooSmall) {
    //     setModalOpen(true);
    // }

    useEffect(() => {
        if (message && message.content) {
            if (message.type === "error") {
                toast.error(message.content, {
                    onClose: () => dispatch(clearMessage()),
                });
            } else if (message.type === "success") {
                toast.success(message.content, {
                    onClose: () => dispatch(clearMessage()),
                });
            }
        }
    }, [message]);

    useEffect(() => {
        if (token) {
            getUser();
        }
    }, [token]);

    useEffect(() => {
        if (clientId) {
            getQuotes({id: Number(clientId), query: {order_by: "created_at", order_dir: "desc"}});
            dispatch(setProjects(projects?.data))
        }
    }, [clientId])

    // useEffect(() => {
    //     if (quotesIsError) {
    //         dispatch(setQuotes([]));
    //     }
    // }, [quotesIsError])

    return (
      <div className="app">
          {
            clientOnboardingLevelTooSmall && <ModalOnboardingClient />
          }
          {
              userOnboardingLevelTooSmall && <ModalOnboardingUser />
          }

          <Routes>
              <Route path="/" element={<AuthGuard><DashboardGuard><Dashboard /></DashboardGuard></AuthGuard>} />
              <Route path='/:clientid' element={<AuthGuard><DashboardGuard><Dashboard /></DashboardGuard></AuthGuard>} />
              <Route path="/:clientid/projects/:productid" element={<AuthGuard><Product /></AuthGuard>} />
              <Route path="/:clientid/new-project" element={<AuthGuard><NewProject /></AuthGuard>} />
              <Route path="/:clientid/my-projects" element={<AuthGuard><MyProjects /></AuthGuard>} />
              <Route path="/:clientid/my-news" element={<AuthGuard><MyNews /></AuthGuard>} />
              <Route path="/:clientid/my-quotes" element={<AuthGuard><Quotes /></AuthGuard>} />
              <Route path="/:clientid/my-quotes/:quoteid" element={<AuthGuard><Quote /></AuthGuard>} />
              <Route path="/:clientid/projects/:productid/pages/:pageid" element={<AuthGuard><ProjectPage /></AuthGuard>} />
              <Route path="/:clientid/projects/:productid/new-ticket" element={<AuthGuard><NewTicket /></AuthGuard>} />
              <Route path="/:clientid/ticket/:id" element={<AuthGuard><Ticket /></AuthGuard>} />
              <Route path="/:clientid/notifications" element={<AuthGuard><Notifications /></AuthGuard>} />
              <Route path="/login" element={
                  <div className="auth-page no-header">
                      <Login />
                      <div className="footer-container">
                          <Footer />
                      </div>
                  </div>
              }
              />
              <Route path="/invite/accept/:code" element={
                  <div className="auth-page no-header">
                      <Invite />
                      <div className="footer-container">
                          <Footer />
                      </div>
                  </div>
              }
              />
              <Route path="/forgot-password" element={
                  <div className="auth-page no-header">
                      <ForgotPassword />
                      <div className="footer-container">
                          <Footer />
                      </div>
                  </div>
              }
              />
              <Route path="/password-reset" element={
                  <div className="auth-page no-header">
                      <ResetPassword />
                      <div className="footer-container">
                          <Footer />
                      </div>
                  </div>
              }
              />
          </Routes>
      </div>
    );
};

export default App;