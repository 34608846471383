import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RootState } from '../store';
import { UserType } from '../types/user';
import { authApi } from '../services/auth-api';

export interface AuthState {
    token: string | null;
    user: UserType | null;
    isAuthenticated: boolean;
    authIsInitialized: boolean;
    clientOnboardingLevelTooSmall: boolean;
    userOnboardingLevelTooSmall: boolean;
}

const token = localStorage.getItem("token");

const initialState: AuthState = {
    token: token || '',
    user: null,
    isAuthenticated: false,
    authIsInitialized: false,
    clientOnboardingLevelTooSmall: false,
    userOnboardingLevelTooSmall: false,
}

export const authSlice = createSlice({
    name: "auth",
    initialState,
    reducers: {
        setAuthToken: (state, action: PayloadAction<{token: string}>) => {
            state.token = action.payload.token;
        },
        setClientOnboardingLevelTooSmall: (state, action: PayloadAction<{clientOnboardingLevelTooSmall: boolean}>) => {
            state.clientOnboardingLevelTooSmall = action.payload.clientOnboardingLevelTooSmall;
        },
        setUserOnboardingLevelTooSmall: (state, action: PayloadAction<{userOnboardingLevelTooSmall: boolean}>) => {
            state.userOnboardingLevelTooSmall = action.payload.userOnboardingLevelTooSmall;
        },
        logout: () => initialState
    },
    extraReducers: (builder) => {
        builder
          .addMatcher(authApi.endpoints.getUser.matchFulfilled,
            (state, action) => {
                state.user = action.payload.data;
                if (!state.authIsInitialized) {
                    state.authIsInitialized = true;
                }
                state.isAuthenticated = true;
            })
    }
})

export const selectToken = (state: RootState) => state.auth.token;
export const selectUser = (state: RootState) => state.auth.user;
export const selectIsAuthenticated = (state: RootState) => state.auth.isAuthenticated;
export const selectAuthIsInitialized = (state: RootState) => state.auth.authIsInitialized;
export const selectClientOnboardingLevelTooSmall = (state: RootState) => state.auth.clientOnboardingLevelTooSmall;
export const selectUserOnboardingLevelTooSmall = (state: RootState) => state.auth.userOnboardingLevelTooSmall;

export const {
    setAuthToken,
    logout,
    setClientOnboardingLevelTooSmall,
    setUserOnboardingLevelTooSmall
} = authSlice.actions;

export default authSlice.reducer;