import { format } from "date-fns";

import userbackIcon from '../../assets/images/icons/userback-icon.png';

import "./user-profile.scss";

export interface UserProfileProps {
  profileIcon?: string;
  initials?: string;
  email?: string;
  date?: Date;
  showTime?: boolean;
  status?: "new" | "in-progress" | "blocked" | "closed";
}

const UserProfile = ({profileIcon, initials, email, date, showTime, status}: UserProfileProps) => {
  return (
    <div className={"user-profile d-flex" + (email ? "" : " wo-email")}>
      <div className="profile-container d-flex align-items-center">
        <div className="profile-icon">
          {profileIcon ? <img src={`${process.env.REACT_APP_URL}${profileIcon}`} alt="profile"/> :
            <div className="initials">
              {initials ? initials : <img src={userbackIcon} alt="Userback" title="Userback"/>}
            </div>}
        </div>
        {email && <span className="email">{email}</span>}
      </div>
      {date ? <span className="date">
              {showTime ? format(date, "d MMM yyyy hh:mm a") : format(date, "d MMM yyyy")}
            </span> : null}
      {status ?
        <p className="status">
          <span className={status} />
          {status.replace("-", " ")}
        </p> : null}
    </div>
  );
};

export default UserProfile;
