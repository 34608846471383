// @ts-ignore
import { createApi } from '@reduxjs/toolkit/dist/query/react';
// @ts-ignore
import baseQueryWithErrorCheck from './base-query';

export const quotesApi = createApi({
  reducerPath: "quotesApi",
  baseQuery: baseQueryWithErrorCheck,
  endpoints: (builder) => ({
    getQuotes: builder.query({
      query: ({id, query}: {id: number, query: Object}) => {
        return {
          url: `/clients/${id}/quotes`,
          method: "get",
          params: {
            ...query
          }
        }
      }
    }),
    getQuote: builder.query({
      query: ({clientId, quoteId}: {clientId: number, quoteId: number}) => {
        return {
          url: `/clients/${clientId}/quotes/${quoteId}`,
          method: "get",
          responseHandler: (response) => response.text()
        }
      },
    }),
    acceptQuote: builder.mutation({
      query: ({clientId, quoteId}: {clientId: number, quoteId: number}) => {
        return {
          url: `/clients/${clientId}/quotes/${quoteId}/accept`,
          method: "post",
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
          },
          body: {_method: 'patch'}
        }
      },
    }),
    replyQuote: builder.mutation({
      query: ({clientId, quoteId, message}: {clientId: number, quoteId: number, message: string}) => {
        return {
          url: `/clients/${clientId}/quotes/${quoteId}/reply`,
          method: "post",
          body: { message }
        }
      },
    }),
    declineQuote: builder.mutation({
      query: ({clientId, quoteId, message}: {clientId: number, quoteId: number, message?: string | null}) => {
        return {
          url: `/clients/${clientId}/quotes/${quoteId}/decline`,
          method: "post",
          body: { message, _method: 'patch' }
        }
      },
    }),
  })
})

export const { useGetQuotesQuery, useLazyGetQuotesQuery, useGetQuoteQuery, useAcceptQuoteMutation, useReplyQuoteMutation, useDeclineQuoteMutation } = quotesApi;