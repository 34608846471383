import { useEffect, useState } from 'react';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { Form, Formik } from 'formik';
import * as Yup from 'yup';

import { setMessage } from '../../../data-access/message-slice';
import { useAppDispatch } from '../../../hooks';
import {
  useResetPasswordMutation,
} from "../../../services/auth-api";

import ButtonAction from '../../../ui/button-action/button-action';
import InputText from '../../../ui/input-text/input-text';

const ResetPassword = () => {
  const [isRecoverSuccess, setIsRecoverSuccess] = useState(false);
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const location = useLocation();
  const params = new URLSearchParams(location.search.replace("+", "%2B"));
  const token = params.get("token");
  const email = decodeURIComponent(params.get("email") || "");

  const [resetPassword,
    {
      data: resetPasswordData,
      isSuccess: isResetPasswordSuccess,
    },
  ] = useResetPasswordMutation();

  useEffect(() => {
    if (isRecoverSuccess) {
      dispatch(setMessage({
        content: resetPasswordData?.data?.status || "Password reset successful.",
        type: "success",
      }));
      navigate("/login")
    }
  }, [isResetPasswordSuccess]);

  return (
    <div className="register-page">
      <div className="container">
        <img className="logo-jump" src={require("../../../assets/images/jump-logo-white.png")}
             alt=""/>
        <div className="auth-form-container">
          <Formik
            initialValues={{password: "", password_confirmation: ""}}
            validationSchema={
              Yup.object({
                password: Yup.string()
                  .min(6, 'Password must be at least 8 characters long.')
                  .required('Password is required.'),
                password_confirmation: Yup.string()
                  .required("Password is required.")
                  .oneOf(
                    [Yup.ref("password"), null],
                    "Passwords must match."
                  )
              })
            }
            onSubmit={async (values, {setSubmitting, resetForm}) => {
              if (values.password) {
                setIsRecoverSuccess(true)
                await resetPassword({
                  email: email || "",
                  token: token || "",
                  password: values.password,
                  password_confirmation: values.password_confirmation
                })
              }
              setSubmitting(false);
              resetForm();
            }}
          >
            {({isSubmitting}) => (
              <Form autoComplete="off">
                <h2>Reset password</h2>
                <div className="input-wrapper">
                  <InputText
                    label="Set a new password"
                    name="password"
                    type="password"
                  />
                </div>
                <div className="input-wrapper">
                  <InputText
                    label="Confirm the password"
                    name="password_confirmation"
                    type="password"
                  />
                </div>
                <ButtonAction
                  buttonType="button"
                  type="submit"
                  primary
                  disabled={isSubmitting}>
                  Reset password
                </ButtonAction>
              </Form>
            )}
          </Formik>
          <div className="links-form">
            <p className="text-md"><Link to="/login">Go to login</Link></p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ResetPassword;