import { createSlice } from '@reduxjs/toolkit';
import { RootState } from '../store';
import { ProjectType } from '../types/project';
import { projectApi } from '../services/project-api';
import { projectPagesApi } from '../services/project-pages-api';
import { logout } from './auth-slice';

export interface ProjectState {
    data: ProjectType | null;
    pages: any[] | null;
}

const initialState: ProjectState = {
    data: null,
    pages: []
}

export const projectSlice = createSlice({
    name: "project",
    initialState,
    reducers: {
        setProject: (state, action) => {
            state.data = action.payload;
        },
        setProjectPages: (state, action) => {
            state.pages = action.payload;
        },
    },
    extraReducers: (builder) => {
        builder
          .addCase(logout, () => {
              return initialState
          })
          .addMatcher(projectApi.endpoints.getProject.matchFulfilled,
            (state, action) => {
                state.data = action.payload.data;
            })
          .addMatcher(projectPagesApi.endpoints.getProjectPages.matchFulfilled,
            (state, action) => {
                state.pages = action.payload.data;
            })
    }
})

export const selectProject = (state: RootState) => state.project.data;
export const selectProjectPages = (state: RootState) => state.project.pages;

export const { setProject, setProjectPages } = projectSlice.actions;

export default projectSlice.reducer;