import { useEffect } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { Form, Formik } from 'formik';
import * as Yup from 'yup';
import { useAppDispatch } from '../../../hooks';
import { setAuthToken } from '../../../data-access/auth-slice';
import { useLoginUserMutation } from '../../../services/auth-api';

import ButtonAction from '../../../ui/button-action/button-action';
import InputText from '../../../ui/input-text/input-text';

const Login = () => {
    const navigate = useNavigate();
    const dispatch = useAppDispatch();

    const [loginUser,
        {
            data: loginData,
            isSuccess: isLoginSuccess,
        }
    ] = useLoginUserMutation();

    useEffect(() => {
        if (isLoginSuccess) {
            localStorage.setItem("token", loginData.data.token);

            navigate('/');

            dispatch(
                setAuthToken({
                    token: loginData.data.token
                })
            );
        }
    }, [isLoginSuccess]);

    return (
        <div className="login-page">
            <div className="container">
                <img className="logo-jump" src={require("../../../assets/images/jump-logo-white.png")} alt=""/>
                <div className="auth-form-container">
                    {/*{ loginError ? <p>{(loginError as any)?.data?.message}</p> : '' }*/}
                    <Formik
                        initialValues={{
                            email: '',
                            password: '',
                        }}
                        validationSchema={
                            Yup.object({
                                email: Yup.string()
                                    .email('Invalid email address')
                                    .required('Email is required'),
                                password: Yup.string()
                                    .min(6, 'Password is too short - should be 8 chars minimum.')
                                    .required('No password provided.'),
                            })
                        }
                        onSubmit={async (values, { setSubmitting, resetForm }) => {
                            if (values.email && values.password) {
                                await loginUser({email: values.email, password: values.password})
                            }
                            setSubmitting(false);
                            resetForm();
                        }}
                    >
                        {({ isSubmitting }) => (
                            <Form autoComplete="off">
                                <h2>Login</h2>
                                <div className="input-wrapper">
                                    <InputText
                                      label="Email"
                                      name="email"
                                      type="email"
                                    />
                                </div>
                                <div className="input-wrapper">
                                    <InputText
                                      label="Password"
                                      name="password"
                                      type="password"
                                    />
                                </div>
                                <ButtonAction
                                    buttonType="button"
                                    type="submit"
                                    primary
                                    disabled={isSubmitting}>
                                    Log in
                                </ButtonAction>
                            </Form>
                        )}
                    </Formik>
                    <div className="links-form">
                        <p className="text-md"><Link to="/forgot-password">Forgot your password?</Link></p>
                        {/*<p className="text-md">Don't have an account? <Link to="/register">Register here!</Link></p>*/}
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Login;