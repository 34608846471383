// @ts-ignore
import { createApi } from '@reduxjs/toolkit/dist/query/react';
// @ts-ignore
import baseQueryWithErrorCheck from './base-query';
import { ClientOnboardingLevel } from "../types/client";

export const clientApi = createApi({
    reducerPath: "clientApi",
    baseQuery: baseQueryWithErrorCheck,
    endpoints: (builder) => ({
        getClient: builder.query({
            query: (id) => {
                return {
                    url: `/${id}`,
                    method: "get",
                }
            }
        }),
        getClientOnboardingLevel: builder.query({
            query: (id: number) => {
                return {
                    url: '/client/onboard',
                    method: "get",
                    params: {
                        clientId: id
                    }
                }
            }
        }),
        setClientOnboardingLevel: builder.mutation<any, ClientOnboardingLevel>({
            query: (args) => {
                return {
                    url: "/client/onboard",
                    method: "post",
                    body: args,
                    headers: { Accept: "application/json" }
                }
            }
        }),
    })
})

export const { useGetClientQuery, useGetClientOnboardingLevelQuery, useSetClientOnboardingLevelMutation } = clientApi;