// @ts-ignore
import { createApi } from '@reduxjs/toolkit/dist/query/react';
// @ts-ignore
import baseQueryWithErrorCheck from './base-query';
import { TicketType } from '../types/ticket';
import { ListResponse } from '../types/list-response';

export const ticketsApi = createApi({
  reducerPath: "ticketsApi",
  baseQuery: baseQueryWithErrorCheck,
  endpoints: (builder) => ({
    getTickets: builder.query<ListResponse<TicketType>, Object>({
      query: ({projectId, page, query}: {projectId: number, page: number, query: Object}) => {
        return {
          url: `/project/${projectId}/ticket?page=${page}`,
          method: "get",
          params: {
            ...query
          }
        }
      }
    }),
    getTicket: builder.query({
      query: (ticketId: number) => {
        return {
          url: `/ticket/${ticketId}`,
          method: "get",
        }
      }
    }),
    postTicket: builder.mutation({
      query: ({projectId, data}: {projectId: number, data: FormData }) => {
        return {
          url: `/project/${projectId}/ticket`,
          method: "post",
          body: data,
        }
      },
    }),
    updateTicket: builder.mutation({
      query: ({ticketId, data}: {ticketId: number, data: Object }) => {
        return {
          url: `/ticket/${ticketId}`,
          method: "put",
          body: data,
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
          },
        }
      },
    }),
    getTicketComments: builder.query({
      query: (ticketId: number) => {
        return {
          url: `/ticket/${ticketId}/comment`,
          method: "get",
        }
      }
    }),
    postTicketComment: builder.mutation({
      query: ({ticketId, data}: {ticketId: number, data: FormData }) => {
        return {
          url: `/ticket/${ticketId}/comment`,
          method: "post",
          body: data,
        }
      },
    }),
  })
})

export const { useGetTicketQuery, useGetTicketsQuery, usePostTicketMutation, useUpdateTicketMutation, useGetTicketCommentsQuery, usePostTicketCommentMutation } = ticketsApi;