import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { Form, Formik } from 'formik';
import * as Yup from 'yup';

import { setMessage } from '../../data-access/message-slice';
import { useAppDispatch } from '../../hooks';
import { useGetProjectPagesQuery } from '../../services/project-pages-api';
import { usePostTicketMutation } from '../../services/ticket-api';

import FormPage from '../../ui/form-page/form-page';
import ButtonAction from '../../ui/button-action/button-action';
import InputText from '../../ui/input-text/input-text';
import InputSelect from '../../ui/input-select/input-select';
import InputTextarea from '../../ui/input-textarea/input-textarea';
import Loader from '../../ui/loader/loader';
import FilesUpload from '../../ui/files-upload/files-upload';

import './new-ticket.scss';

const NewTicket = () => {
  const [filesBlob, setFilesBlob] = useState<FileList | null | Array<any>>(null);
  const [resetFiles, setResetFiles] = useState<boolean>(false);
  const dispatch = useAppDispatch();
  const {clientid, productid} = useParams();

  const [newTicket, {
    isSuccess: isNewTicketSuccess,
    isLoading: isNewTicketLoading,
    error: newTicketError
  }] = usePostTicketMutation();

  const {
    data: projectPages,
    isLoading: projectPagesIsLoading,
    isFetching: projectPagesIsFetching,
    isSuccess: projectPagesIsSuccess,
    error: projectPagesError,
  } = useGetProjectPagesQuery({clientId: Number(clientid), projectId: Number(productid)});

  useEffect(() => {
    if (isNewTicketSuccess) {
      dispatch(setMessage({
        content: "Ticket created successfully.",
        type: "success"
      }));
      setResetFiles(true);
    }
  }, [isNewTicketSuccess, newTicketError, projectPagesError])

  return (
    <FormPage pageTitle="New ticket">
      {(projectPagesIsLoading || projectPagesIsFetching) ? <Loader/> :
      <Formik
        initialValues={{
          title: '',
          description: '',
          priority: 'low',
        }}
        validationSchema={
          Yup.object({
            title: Yup.string().required('This field is required'),
            description: Yup.string().required('This field is required'),
            priority: Yup.string().nullable(),
          })
        }
        onSubmit={async (values, { setSubmitting, resetForm }) => {
          try {
            const ticketObject: any = {
              title: values.title,
              description: values.description,
              priority: values.priority,
              attachments: filesBlob,
            }

            const formData = new FormData();
            Object.keys(ticketObject).forEach(key => {
                if (key === 'attachments' && ticketObject.attachments) {
                  ticketObject[key].forEach((file: any) => formData.append("attachments[]", file));
                } else {
                    formData.append(key, ticketObject[key]);
                }
            });

            await newTicket({projectId: Number(productid), data: formData})
              .unwrap()
              .then(() => {
                setSubmitting(false);
                resetForm();
                setResetFiles(true);
              })
          } catch (error: any) {
            console.error('Failed to save the post: ', error)
            // setStatus({ success: false });
            // setErrors({ submit: message });
            setSubmitting(false);
          }
        }}
      >
        {({ isSubmitting }) => (
          <Form autoComplete="off">
            <div className="input-wrapper">
              <InputText
                label="Title"
                name="title"
                id="title"
                type="text"
                required
              />
            </div>
            <div className="input-wrapper">
              <InputTextarea
                label="Description"
                name="description"
                id="description"
                required
              />
            </div>
            <div className="input-wrapper">
              <InputSelect
                label="Priority"
                name="priority">
                <option value="low">Low</option>
                <option value="high">High</option>
                <option value="urgent">Urgent</option>
              </InputSelect>
            </div>
            <div className="input-wrapper">
              <FilesUpload
                label="Files"
                reset={resetFiles}
                uploadFilesHandler={(files: FileList | null | Array<any>) => setFilesBlob(files) }/>
            </div>
            <ButtonAction
              buttonType="button"
              type="submit"
              primary
              disabled={isSubmitting || isNewTicketLoading}>
              Lets go!
            </ButtonAction>
          </Form>
        )}
      </Formik> }
    </FormPage>
  );
};

export default NewTicket;