import React, { useEffect, useState } from 'react';
import Modal from "react-modal";
import { Form, Formik } from 'formik';
import * as Yup from 'yup';
import { useAppSelector } from "../../hooks";
import {
  useGetUserOnboardingLevelQuery,
  useSetUserOnboardingLevelMutation,
} from "../../services/auth-api";

import { selectUser, selectUserOnboardingLevelTooSmall } from "../../data-access/auth-slice";
import { selectClient, selectClientId } from "../../data-access/client-slice";

import ButtonAction from '../../ui/button-action/button-action';
import InputText from '../../ui/input-text/input-text';

const ModalOnboardingUser = () => {
  const userOnboardingLevelTooSmall = useAppSelector(selectUserOnboardingLevelTooSmall);
  const clientId = useAppSelector(selectClientId);
  const client = useAppSelector(selectClient);
  const user = useAppSelector(selectUser);
  const validationSchemaUserOnboard = Yup.object({
    name: Yup.string()
      .required('Name is required'),
    job_title: Yup.string(),
    client_name: Yup.string()
      .when(['client_reg_number', 'client_reg_address', 'client_email'], {
        is: (regNumber: string, regAddress: string, email: string) => {
          return regNumber || regAddress || email;
        },
        then: Yup.string().required('Name is required'),
      }),
    client_reg_number: Yup.string(),
    client_reg_address: Yup.string(),
    client_email: Yup.string()
      .email('Invalid email address'),
  });

  const {
    data: userOnboardingLevel,
  } = useGetUserOnboardingLevelQuery({skip: !clientId || !userOnboardingLevelTooSmall});

  const [onboardUser] = useSetUserOnboardingLevelMutation();

  const [modalOpen, setModalOpen] = useState<boolean>(false);

  Modal.setAppElement('body');

  useEffect(() => {
    if (userOnboardingLevelTooSmall) {
      setModalOpen(true);
    }
  }, [userOnboardingLevelTooSmall]);

  return (
    <Modal
      isOpen={modalOpen}
      onRequestClose={() => setModalOpen(false)}
      className="modal modal-invite"
      overlayClassName="modal-overlay"
    >
      <div className="modal-content">
        <button className="close" onClick={() => setModalOpen(false)}></button>

        {
          client?.canUpdate ?
            <div>
              <h2>Onboarding level too small</h2>
              <p>You need to complete the onboarding process to have access to the data.</p>
              <br/>

              <Formik
                initialValues={{
                  name: user?.name || "",
                  phone: user?.phone || "",
                  job_title: user?.job_title || "",
                  client_name: client?.name || "",
                  client_reg_number: client?.registration_number || "",
                  client_reg_address: client?.registration_address || "",
                  client_email: client?.email || "",
                }}
                validationSchema={validationSchemaUserOnboard}
                onSubmit={async (values, {setSubmitting, resetForm, setFieldValue}) => {
                  try {
                    const onBoardingObject = {
                      onboarding_level: userOnboardingLevel?.data?.onboarding_level + 1,
                      name: values.name,
                      phone: values.phone,
                      job_title: values.job_title,
                      ...(values.client_name && {
                        client: {
                          name: values.client_name,
                          reg_number: values.client_reg_number,
                          reg_address: values.client_reg_address,
                          email: values.client_email,
                        },
                      }),
                    }

                    await onboardUser(onBoardingObject)
                      .unwrap()
                      .then(() => {
                        setSubmitting(false);
                        resetForm();
                        setFieldValue('client_name', '');
                        setFieldValue('client_reg_number', '');
                        setFieldValue('client_reg_address', '');
                        setFieldValue('client_email', '');
                      });
                  } catch (error: any) {
                    console.log(error);
                    setSubmitting(false);
                  }
                }}
              >
                {({isSubmitting, values}) => (
                  <Form autoComplete="off">
                    <div className="input-wrapper">
                      <InputText
                        label="Name"
                        name="name"
                        type="text"
                        required
                      />
                    </div>
                    <div className="input-wrapper">
                      <InputText
                        label="Phone"
                        name="phone"
                        type="tel"
                      />
                    </div>
                    <div className="input-wrapper">
                      <InputText
                        label="Job title"
                        name="job_title"
                        type="text"
                      />
                    </div>
                    <div className="input-wrapper">
                      <InputText
                        label="Client name"
                        name="client_name"
                        type="text"
                        required={!!(values.client_reg_number || values.client_reg_address || values.client_email)}
                      />
                    </div>
                    <div className="input-wrapper">
                      <InputText
                        label="Client registration number"
                        name="client_reg_number"
                        type="text"
                      />
                    </div>
                    <div className="input-wrapper">
                      <InputText
                        label="Client registration address"
                        name="client_reg_address"
                        type="text"
                      />
                    </div>
                    <div className="input-wrapper">
                      <InputText
                        label="Client email"
                        name="client_email"
                        type="email"
                      />
                    </div>
                    <ButtonAction
                      buttonType="button"
                      type="submit"
                      primary
                      disabled={isSubmitting}
                    >
                      Complete onboarding
                    </ButtonAction>
                  </Form>
                )}
              </Formik>
            </div>
            : <p>We need more informations about your company. Please contact us.</p>
        }
      </div>
    </Modal>
  );
};

export default ModalOnboardingUser;