import { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { Form, Formik } from 'formik';
import * as Yup from 'yup';

import { setMessage } from '../../../data-access/message-slice';
import { useAppDispatch } from '../../../hooks';
import { useRecoverPasswordMutation } from "../../../services/auth-api";

import ButtonAction from '../../../ui/button-action/button-action';
import InputText from '../../../ui/input-text/input-text';

const ForgotPassword = () => {
  const [isRecoverSuccess, setIsRecoverSuccess] = useState(false);
  const dispatch = useAppDispatch();

  const [recoverPassword,
    {
      data: recoverPasswordData,
      isSuccess: isRecoverPasswordSuccess,
    },
  ] = useRecoverPasswordMutation();

  useEffect(() => {
    if (isRecoverSuccess) {
      dispatch(setMessage({
        content: recoverPasswordData?.data?.status || "We have emailed your password reset link!",
        type: "success",
      }));
    }
  }, [isRecoverPasswordSuccess]);

  return (
    <div className="register-page">
      <div className="container">
        <img className="logo-jump" src={require("../../../assets/images/jump-logo-white.png")}
             alt=""/>
        <div className="auth-form-container">
          <Formik
            initialValues={{email: ''}}
            validationSchema={
              Yup.object({
                email: Yup.string()
                  .email('Invalid email address')
                  .required('Email is required'),
              })
            }
            onSubmit={async (values, {setSubmitting, resetForm}) => {
              if (values.email) {
                setIsRecoverSuccess(true)
                await recoverPassword({email: values.email})
              }
              setSubmitting(false);
              resetForm();
            }}
          >
            {({isSubmitting}) => (
              <Form autoComplete="off">
                <h2>Recover password</h2>
                <div className="input-wrapper">
                  <InputText
                    label="Email"
                    name="email"
                    type="email"
                  />
                </div>
                <ButtonAction
                  buttonType="button"
                  type="submit"
                  primary
                  disabled={isSubmitting}>
                  Recover password
                </ButtonAction>
              </Form>
            )}
          </Formik>
          <div className="links-form">
            <p className="text-md"><Link to="/login">Go back to login</Link></p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ForgotPassword;