import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faUpload} from "@fortawesome/free-solid-svg-icons";
import React, { ChangeEvent, useEffect, useState } from 'react';
import { fileExtensions, filesizeChecker, formatBytes } from '../../utils/helpers';

import ButtonAction from '../button-action/button-action';

import './files-upload.scss';

interface FilesUploadProps {
  label?: string;
  id?: string | number;
  uploadFilesHandler: any;
  reset: boolean;
}

const FilesUpload = ({uploadFilesHandler, label, id, reset = false}: FilesUploadProps) => {
  const [inputFilesLength, setInputFilesLength] = useState(0);
  const [filesBlob, setFilesBlob] = useState<FileList | null | Array<any>>(null);
  const [errorFiles, setErrorFiles] = useState<string>('');

  useEffect(() => {
    setFilesBlob([])
    setInputFilesLength(0);
    setErrorFiles('');
  }, [reset])

  const uploadFilesSelectedHandler = (e: ChangeEvent<HTMLInputElement>): void => {
    let files = e.target.files!;

    if (!filesizeChecker(files)) {
      setInputFilesLength(files.length);
      const fileArray = Array.from(files)
      setFilesBlob(fileArray);
      uploadFilesHandler(fileArray);
      setErrorFiles('');
    } else {
      setErrorFiles(filesizeChecker(files));
      setInputFilesLength(0);
      return
    }
  }

  return (
    <div className="files-upload">
      {label ?
        <p className="text-md label">{label}</p> : null}
      <div className="buttons-wrapper">
        <label htmlFor={`contained-button-file-${id}`}>
          <input
            key={inputFilesLength}
            accept={fileExtensions}
            id={`contained-button-file-${id}`}
            multiple
            type="file"
            className="hidden-input"
            hidden
            onChange={uploadFilesSelectedHandler}
          />
          <div className="btn-upload">
            <ButtonAction buttonType="button" type="button" primary outline>
              {inputFilesLength === 0 ? (
                  <>
                    Browse files
                    <FontAwesomeIcon icon={faUpload} />
                  </>
                ) :
                (inputFilesLength === 1 ?
                    <>
                      <FontAwesomeIcon icon={faUpload} />
                      1 file selected
                    </>
                    :
                    <>
                      <FontAwesomeIcon icon={faUpload} />
                      {inputFilesLength} files selected
                    </>
                )}
            </ButtonAction>
          </div>
        </label>
        {inputFilesLength !== 0 &&
          <ButtonAction
            buttonType="button" type="button" primary text color="error"
            onClick={() => {
              setFilesBlob(null);
              uploadFilesHandler(null);
              setInputFilesLength(0);
            }}>Remove files</ButtonAction>
        }
      </div>

      {errorFiles ?
        <p className="error-message">
          {errorFiles}
        </p> : null
      }
      {(Array.isArray(filesBlob) && filesBlob.length) ?
        filesBlob.map((file: File, index: number) =>
          <p key={`${index}-${file.name}`} className="file-name">
            <svg aria-hidden="true" viewBox="0 0 24 24">
              <path d="M16.5 6v11.5c0 2.21-1.79 4-4 4s-4-1.79-4-4V5c0-1.38 1.12-2.5 2.5-2.5s2.5 1.12 2.5 2.5v10.5c0 .55-.45 1-1 1s-1-.45-1-1V6H10v9.5c0 1.38 1.12 2.5 2.5 2.5s2.5-1.12 2.5-2.5V5c0-2.21-1.79-4-4-4S7 2.79 7 5v12.5c0 3.04 2.46 5.5 5.5 5.5s5.5-2.46 5.5-5.5V6h-1.5z"></path>
            </svg>
            {`${file.name} (${formatBytes(file.size)})`}
          </p>
        ) : null}
      <p className="instructions">
        You can upload 2 files max (up to 2MB each). Accepted types:
        {fileExtensions.split(", ").map((extension, index) => (
          <span className="chip" key={index}>{extension} </span>
        ))}
      </p>
    </div>
  );
};

export default FilesUpload;