import { ErrorMessage, Field, FieldHookConfig, useField } from 'formik';
import { OtherProps } from '../input-text/input-text';

import './input-textarea.scss';

const InputTextarea = (props : OtherProps & FieldHookConfig<string>) => {
  const [field] = useField(props);
  return (
    <div className="input-field">
      {props.label ?
        <label htmlFor={props.id || props.name} className="text-md">
          {props.label} {props.required && <span className="required">*</span>}
        </label> : null}
      {props.description && <p>{props.description}</p>}
      <Field className="textarea-input" as="textarea" placeholder={props.placeholder} id={props.id} {...field} />
      <ErrorMessage className="error-message" component="p" name={props.id || props.name} />
    </div>
  );
};

export default InputTextarea;