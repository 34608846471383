import { Form, Formik } from 'formik';
import * as Yup from 'yup';

import FormPage from '../../ui/form-page/form-page';
import ButtonAction from '../../ui/button-action/button-action';
import InputText from '../../ui/input-text/input-text';
import InputSelect from '../../ui/input-select/input-select';
import InputTextarea from '../../ui/input-textarea/input-textarea';

import './new-project.scss';

const NewProject = () => {
    return (
        <FormPage pageTitle="New project">
          <Formik
            initialValues={{
              projectName: '',
              category: '',
              message: ''
            }}
            validationSchema={
              Yup.object({
                projectName: Yup.string()
                  .required('Project name is required'),
                category: Yup.string()
                  .oneOf(
                    ['designer', 'development', 'product'],
                    'Select a category'
                  )
                  .required('Select a category'),
                message: Yup.string().required('This field is required')
              })
            }
            onSubmit={(values, { setSubmitting, resetForm }) => {
              setTimeout(() => {
                alert(JSON.stringify(values, null, 2));
                setSubmitting(false);
                resetForm();
              }, 400);
            }}
          >
            {({ isSubmitting }) => (
              <Form autoComplete="off">
                <div className="input-wrapper">
                  <InputText
                    label="Suggest a project name"
                    description="This would be the name we use to talk about your project in the future"
                    name="projectName"
                    id="projectName"
                    type="text"
                  />
                </div>
                <div className="input-wrapper">
                  <InputSelect
                    label="Choose a project category"
                    description="Choose a category from the list below which best patches your needs"
                    name="category">
                    <option>Select a category</option>
                    <option value="designer">Designer</option>
                    <option value="development">Developer</option>
                    <option value="product">Product Manager</option>
                  </InputSelect>
                </div>
                <InputTextarea
                  name="message"
                  placeholder="Write something here"
                />
                <ButtonAction
                  buttonType="button"
                  type="submit"
                  primary
                  disabled={isSubmitting}>
                  Lets go!
                </ButtonAction>
              </Form>
            )}
          </Formik>
        </FormPage>
    );
};

export default NewProject;