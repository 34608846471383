import './card-product.scss';

export interface CardProductProps {
    title: string;
    description?: string;
    button: JSX.Element;
    light?: boolean;
    image?: string;
}

const CardProduct = ({title, description, button, light, image}: CardProductProps) => {
    const CARD_CLASS = "card-product" + (light ? " light" : "")
    + (image ? " hasBg" : "");

    return (
        <div className={CARD_CLASS} style={{ backgroundImage: image ? `url(${image})` : '' }}>
            <h4>{title}</h4>
            {description && <p className="text-md">{description}</p>}
            <div className="btn-wrapper">
                {button}
            </div>
        </div>
    );
};

export default CardProduct;