import CardsList from '../cards-list/cards-list';
import CardProduct from '../card-product/card-product';
import ButtonAction from '../button-action/button-action';
import { Post } from '../../types/project';

import "./supercharge-projects.scss";

export interface SuperchargeProjectsProps {
  title?: string;
  added_extras: Post[];
}

const SuperchargeProjects = ({title = "Supercharge your projects", added_extras}: SuperchargeProjectsProps) => {
  return (
    <CardsList
      title={title}
    >
      <div className="columns">
        {added_extras.map((project, index) => {
          return (
            <div className="col-6 col-card" key={index}>
              <CardProduct
                image={project.image}
                title={project.title}
                description={project.description}
                button={<ButtonAction buttonType="external_link" primary outline light
                                      href={project.external_url}
                                      target="_blank" rel="noreferrer">{project.button_label}</ButtonAction>}
              />
            </div>
          )
        })}
      </div>
    </CardsList>
  );
};

export default SuperchargeProjects;