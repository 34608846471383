// @ts-ignore
import { createApi } from '@reduxjs/toolkit/dist/query/react';
// @ts-ignore
import baseQueryWithErrorCheck from './base-query';

export const projectApi = createApi({
    reducerPath: "projectApi",
    baseQuery: baseQueryWithErrorCheck,
    endpoints: (builder) => ({
        getProject: builder.query({
            query: ({clientId, projectId}: {clientId: number, projectId: number}) => {
                return {
                    url: `/${clientId}/projects/${projectId}`,
                    method: "get"
                }
            }
        }),
    })
})

export const { useGetProjectQuery, useLazyGetProjectQuery } = projectApi;