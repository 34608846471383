import { configureStore } from '@reduxjs/toolkit';
import { setupListeners } from '@reduxjs/toolkit/query';
import { authApi } from './services/auth-api';
import authReducer from './data-access/auth-slice';
import { projectsApi } from './services/projects-api';
import { clientApi } from './services/client-api';
import clientReducer from './data-access/client-slice';
import { projectApi } from './services/project-api';
import projectReducer from './data-access/project-slice';
import { projectPagesApi } from './services/project-pages-api';
import { pageApi } from './services/page-api';
import { quotesApi } from './services/quotes-api';
import { ticketsApi } from './services/ticket-api';
import { notificationsApi } from './services/notifications-api';
import messageReducer from "./data-access/message-slice";

export const store = configureStore({
    reducer: {
        [authApi.reducerPath]: authApi.reducer,
        auth: authReducer,
        message: messageReducer,
        client: clientReducer,
        [clientApi.reducerPath]: clientApi.reducer,
        [projectsApi.reducerPath]: projectsApi.reducer,
        project: projectReducer,
        [projectApi.reducerPath]: projectApi.reducer,
        [projectPagesApi.reducerPath]: projectPagesApi.reducer,
        [pageApi.reducerPath]: pageApi.reducer,
        [quotesApi.reducerPath]: quotesApi.reducer,
        [ticketsApi.reducerPath]: ticketsApi.reducer,
        [notificationsApi.reducerPath]: notificationsApi.reducer,
    },
    middleware: (getDefaultMiddleware) =>
        getDefaultMiddleware().concat(
          authApi.middleware,
          projectsApi.middleware,
          clientApi.middleware,
          projectApi.middleware,
          projectPagesApi.middleware,
          pageApi.middleware,
          quotesApi.middleware,
          ticketsApi.middleware,
          notificationsApi.middleware
        ),
});

export type RootState = ReturnType<typeof store.getState>
export type AppDispatch = typeof store.dispatch
setupListeners(store.dispatch);