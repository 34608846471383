import { Field, ErrorMessage, FieldHookConfig, useField } from 'formik';

import './input-text.scss';

export interface OtherProps {
    label?: string;
    description?: string;
    disabled?: boolean;
}

const InputText = (props : OtherProps & FieldHookConfig<string>) => {
    const [field] = useField(props);
    return (
        <div className="input-field">
          {props.label ?
            <label htmlFor={props.id || props.name} className="text-md">
              {props.label} {props.required && <span className="required">*</span>}
            </label> : null}
            {props.description && <p>{props.description}</p>}
            <Field className="text-input" placeholder={props.placeholder} type={props.type} id={props.id} disabled={props.disabled} {...field} />
            <ErrorMessage className="error-message" component="p" name={props.id || props.name} />
        </div>
    );
};

export default InputText;