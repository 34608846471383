import { ReactNode } from 'react';
import { useParams } from 'react-router-dom';

import Header from '../header/header';
import Footer from '../footer/footer';
import ButtonBack from '../button-back/button-back';

import "./form-page.scss";

interface FormPageProps {
  pageTitle: string;
  children: ReactNode;
}

const FormPage = ({ pageTitle, children }: FormPageProps) => {
  const { clientid } = useParams();

  return (
    <>
      <Header />
      <div className="form-page">
        <ButtonBack to={`/${clientid}`} title="Back to Dashboard"/>
        <div className="container">
          <h1>{pageTitle}</h1>
          {children}
        </div>
        <div className="footer-container">
          <Footer />
        </div>
      </div>
    </>
  );
};

export default FormPage;