import { createSlice } from '@reduxjs/toolkit';
import { RootState } from '../store';
import { clientApi } from '../services/client-api';
import { Project, projectsApi } from '../services/projects-api';
import { quotesApi } from '../services/quotes-api';
import { logout } from './auth-slice';
import { Client } from '../types/client';

export interface ClientState {
  client: Client | null;
  clientId: number | null,
  projects: Project[] | null;
  quotes: any[] | null;
}

const initialState: ClientState = {
  client: null,
  clientId: null,
  projects: [],
  quotes: [],
}

export const clientSlice = createSlice({
  name: "client",
  initialState,
  reducers: {
    setClient: (state, action) => {
      state.client = action.payload;
    },
    setClientId: (state, action) => {
      state.clientId = action.payload;
    },
    setProjects: (state, action) => {
      state.projects = action.payload;
    },
    setQuotes: (state, action) => {
      state.quotes = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(logout, () => {
        return initialState
      })
      // .addMatcher(projectsApi.endpoints.getProjects.matchFulfilled,
      //   (state, action) => {
      //     state.projects = action.payload.data;
      //   })
      .addMatcher(clientApi.endpoints.getClient.matchFulfilled,
        (state, action) => {
          state.client = action.payload.data;
          // state.added_extras = action.payload.data.added_extras;
          // state.news = action.payload.data.posts;
          // state.clientName = action.payload.data.name;
        })
      .addMatcher(quotesApi.endpoints.getQuotes.matchFulfilled,
        (state, action) => {
          state.quotes = action.payload;
        })
      .addMatcher(projectsApi.endpoints.getProjects.matchFulfilled,
        (state, action) => {
          state.projects = action.payload.data;
        })
      .addMatcher(projectsApi.endpoints.getProjects.matchRejected,
        (state) => {
          state.projects = [];
        })
      .addMatcher(quotesApi.endpoints.getQuotes.matchRejected,
        (state) => {
          state.quotes = [];
        })
  },
})

export const selectClient = (state: RootState) => state.client.client;
export const selectProjects = (state: RootState) => state.client.projects;
export const selectClientId = (state: RootState) => state.client.clientId;
export const selectQuotes = (state: RootState) => state.client.quotes;

export const {
  setClient,
  setClientId,
  setProjects,
  setQuotes,
} = clientSlice.actions;

export default clientSlice.reducer;