import React, { useEffect, useState } from 'react';
import Modal from "react-modal";
import { Form, Formik } from 'formik';
import * as Yup from 'yup';
import { useAppSelector } from "../../hooks";

import { selectClientOnboardingLevelTooSmall } from "../../data-access/auth-slice";
import { selectClient, selectClientId } from "../../data-access/client-slice";
import {
  useGetClientOnboardingLevelQuery,
  useSetClientOnboardingLevelMutation,
} from "../../services/client-api";

import ButtonAction from '../../ui/button-action/button-action';
import InputText from '../../ui/input-text/input-text';

const ModalOnboardingClient = () => {
  const clientOnboardingLevelTooSmall = useAppSelector(selectClientOnboardingLevelTooSmall);
  const clientId = useAppSelector(selectClientId);
  const client = useAppSelector(selectClient);
  const validationSchemaClientOnboard = Yup.object({
    client_name: Yup.string()
      .required('Name is required'),
    client_reg_number: Yup.string()
      .required('Registration number is required'),
    client_reg_address: Yup.string()
      .required('Registration address is required'),
    client_email: Yup.string()
      .email('Invalid email address')
      .required('Email is required'),
  });

  const {
    data: clientOnboardingLevel,
  } = useGetClientOnboardingLevelQuery(Number(clientId), {skip: !clientId || !clientOnboardingLevelTooSmall || !client?.canUpdate});

  const [onboardClient] = useSetClientOnboardingLevelMutation();

  const [modalOpen, setModalOpen] = useState<boolean>(false);

  Modal.setAppElement('body');

  useEffect(() => {
    if (clientOnboardingLevelTooSmall) {
      setModalOpen(true);
    }
  }, [clientOnboardingLevelTooSmall]);

  return (
    <Modal
      isOpen={modalOpen}
      onRequestClose={() => setModalOpen(false)}
      className="modal modal-invite"
      overlayClassName="modal-overlay"
    >
      <div className="modal-content">
        <button className="close" onClick={() => setModalOpen(false)}></button>

        {
          client?.canUpdate ?
            <div>
              <h2>Onboarding level too small</h2>
              <p>You need to complete the onboarding process to have access to the data</p>
              <br/>

              <Formik
                initialValues={{
                  client_name: client?.name || "",
                  client_reg_number: client?.registration_number || "",
                  client_reg_address: client?.registration_address || "",
                  client_email: client?.email || "",
                }}
                validationSchema={validationSchemaClientOnboard}
                onSubmit={async (values, {setSubmitting, resetForm}) => {
                  try {
                    const onBoardingObject = {
                      onboarding_level: clientOnboardingLevel?.data?.onboarding_level + 1,
                      client_id: client?.id || Number(clientId),
                      name: values.client_name,
                      reg_number: values.client_reg_number,
                      reg_address: values.client_reg_address,
                      email: values.client_email,
                    }

                    await onboardClient(onBoardingObject)
                      .unwrap()
                      .then(() => {
                        setSubmitting(false);
                        resetForm();
                      });
                  } catch (error: any) {
                    console.log(error);
                    setSubmitting(false);
                  }
                }}
              >
                {({isSubmitting}) => (
                  <Form autoComplete="off">
                    <div className="input-wrapper">
                      <InputText
                        label="Client name"
                        name="client_name"
                        type="text"
                        required
                      />
                    </div>
                    <div className="input-wrapper">
                      <InputText
                        label="Client registration number"
                        name="client_reg_number"
                        type="text"
                        required
                      />
                    </div>
                    <div className="input-wrapper">
                      <InputText
                        label="Client registration address"
                        name="client_reg_address"
                        type="text"
                        required
                      />
                    </div>
                    <div className="input-wrapper">
                      <InputText
                        label="Client email"
                        name="client_email"
                        type="email"
                        required
                      />
                    </div>
                    <ButtonAction
                      buttonType="button"
                      type="submit"
                      primary
                      disabled={isSubmitting}
                    >
                      Complete onboarding
                    </ButtonAction>
                  </Form>
                )}
              </Formik>
            </div>
            : <p>We need more informations about your company. Please contact us.</p>
        }
      </div>
    </Modal>
  );
};

export default ModalOnboardingClient;