import { createApi } from '@reduxjs/toolkit/dist/query/react';
import baseQueryWithErrorCheck from './base-query';
import { UserType, InviteType } from '../types/user';
import {
  RecoverPasswordPayload,
  RecoverPasswordResponse,
  RegisterPayload,
  RegisterResponse, ResetPasswordPayload, ResetPasswordResponse,
  UserOnboardingLevel,
} from "../types/auth";

export const authApi = createApi({
  reducerPath: "authApi",
  baseQuery: baseQueryWithErrorCheck,
  endpoints: (builder) => ({
    loginUser: builder.mutation({
      query: (body: { email: string; password: string }) => {
        return {
          url: "/login",
          method: "post",
          body,
        }
      },
    }),
    registerUser: builder.mutation<RegisterResponse, RegisterPayload>({
      query: (args) => {
        return {
          url: "/register",
          method: "post",
          body: args,
          headers: {Accept: "application/json"},
        }
      },
    }),
    recoverPassword: builder.mutation<RecoverPasswordResponse, RecoverPasswordPayload>({
      query: (args) => {
        return {
          url: "/password/forgot",
          method: "post",
          body: args,
          headers: {Accept: "application/json"},
        }
      },
    }),
    resetPassword: builder.mutation<ResetPasswordResponse, ResetPasswordPayload>({
      query: (args) => {
        return {
          url: "/password/reset",
          method: "post",
          body: args,
          headers: {Accept: "application/json"},
        }
      },
    }),
    getUser: builder.query<{ data: UserType }, void>({
      query: () => {
        return {
          url: "/user",
          method: "get",
        }
      },
    }),
    getInviteCode: builder.query<{ data: InviteType }, string>({
      query: (code: string) => {
        return {
          url: `/invite/${code}`,
          method: "get",
        }
      },
    }),
    getUserOnboardingLevel: builder.query({
      query: () => {
        return {
          url: '/user/onboard',
          method: "get",
        }
      },
    }),
    setUserOnboardingLevel: builder.mutation<any, UserOnboardingLevel>({
      query: (args) => {
        return {
          url: "/user/onboard",
          method: "post",
          body: args,
          headers: {Accept: "application/json"},
        }
      },
    }),
  }),
});

export const {
  useLoginUserMutation,
  useRegisterUserMutation,
  useRecoverPasswordMutation,
  useResetPasswordMutation,
  useGetInviteCodeQuery,
  useLazyGetUserQuery,
  useGetUserOnboardingLevelQuery,
  useSetUserOnboardingLevelMutation,
} = authApi;