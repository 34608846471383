import { useAppSelector } from '../../hooks';
import { selectClientId } from '../../data-access/client-slice';
import { useGetClientQuery } from '../../services/client-api';
import { Post } from '../../types/project';

import Header from '../../ui/header/header';
import Footer from '../../ui/footer/footer';
import CardProduct from '../../ui/card-product/card-product';
import ButtonAction from '../../ui/button-action/button-action';
import ButtonBack from '../../ui/button-back/button-back';
import ListingProjects from '../../ui/listing-projects/listing-projects';
import Loader from '../../ui/loader/loader';

import './my-news.scss';

const MyNews = () => {
  const clientId = useAppSelector(selectClientId);

  const {
    data: client,
    isLoading: clientIsLoading,
    isFetching: clientIsFetching,
    isSuccess: clientIsSuccess,
  } = useGetClientQuery(clientId, {skip: !(!!clientId)});

  return (
    <>
      <Header/>
      <div className="my-news-page">
        <ButtonBack to={`/${clientId}`} title="Back to Dashboard"/>
        <div className="container">
          <h1>My news</h1>
          {clientIsLoading || clientIsFetching ? <Loader/> :
            (clientIsSuccess && client.data && client.data.posts) ?
              <ListingProjects>
                {client.data.posts.map((post: Post, index: number) =>
                  <div className="card-wrapper" key={`post-${index}`}>
                    <CardProduct
                      image={post.image}
                      title={post.title}
                      description={post.description}
                      button={<ButtonAction buttonType="external_link" primary outline light href={post.external_url} target="_blank" rel="noreferrer">{post.button_label}</ButtonAction>}
                    />
                  </div>
                )}
              </ListingProjects> : null}
        </div>
      </div>
      <Footer/>
    </>
  );
};

export default MyNews;