import './button-with-icon.scss';

export interface ButtonWithIconProps {
  title: string;
  icon: JSX.Element;
  action: () => void;
  color?: string;
}


const ButtonWithIcon = ({title, action, icon, color, ...restProps}: ButtonWithIconProps) => {
  return (
    <div {...(restProps)} className="button-with-icon" onClick={action} style={{color}}>
      {icon}
      {title}
    </div>
  );
};

export default ButtonWithIcon;