import './footer.scss';

const Footer = () => {
  return (
    <div className="main-footer">
      <div className="container">
        <p className="text-md">Copyright © Jump Media Group Ltd. All rights reserved</p>
      </div>
    </div>
  );
};

export default Footer;