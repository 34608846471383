import React from "react";
import { Navigate } from 'react-router-dom';
import { useAppSelector } from '../hooks';
import { selectClientId } from "../data-access/client-slice";
import { useGetProjectsQuery } from "../services/projects-api";
import Loader from "../ui/loader/loader";

interface DashboardGuardType {
  children: React.ReactNode;
}

const DashboardGuard = ({children}: DashboardGuardType) => {
  const clientId = useAppSelector(selectClientId);
  const {
    data: projects,
    isLoading: projectsIsLoading,
    isFetching: projectsIsFetching
  } = useGetProjectsQuery(Number(clientId), {skip: !(!!clientId)});

  if (projectsIsLoading || projectsIsFetching) {
    return <Loader/>
  }

  if (projects && projects.data.length < 2) {
    return <Navigate to={`/${clientId}/projects/${projects.data[0].id}`} replace />
  }

  return (
    <>
      {children}
    </>
  );
};

export default DashboardGuard;