import { useState, useRef } from 'react';
import { useParams } from 'react-router-dom';

import { useAppSelector } from '../../hooks';
import { useGetProjectQuery } from '../../services/project-api';
import { useGetProjectPagesQuery } from '../../services/project-pages-api';
import { useGetTicketsQuery } from '../../services/ticket-api';
import { selectProjects } from "../../data-access/client-slice";

import { firstLettersInName } from '../../utils/helpers';
import SuperchargeProjects from '../../ui/supercharge-projects/supercharge-projects';
import Pagination from '../../ui/pagination/pagination';
import Header from '../../ui/header/header';
import Footer from '../../ui/footer/footer';
import CardProduct from '../../ui/card-product/card-product';
import ButtonAction from '../../ui/button-action/button-action';
import CardsList from '../../ui/cards-list/cards-list';
import TicketCard from "../../ui/ticket-card/ticket-card";
import ButtonBack from '../../ui/button-back/button-back';
import UserProfile from "../../ui/user-profile/user-profile";
import Loader from '../../ui/loader/loader';
import SortBy from '../../ui/sort-by/sort-by';
import { SortType } from '../quotes/quotes';

import './product.scss';

const Product = () => {
  const [filters, setFilters] = useState<SortType>({order_by: "created_at", order_dir: "desc"});
  const [pageTickets, setPageTickets] = useState<number>(1);
  const [closedTickets, setClosedTickets] = useState<boolean>(false);
  const pageTopRef = useRef<HTMLDivElement>(null);
  const {clientid, productid} = useParams();
  const projects = useAppSelector(selectProjects);

  const {
    data: project,
    isFetching: projectIsFetching,
    isLoading: projectIsLoading,
    isSuccess: projectIsSuccess,
  } = useGetProjectQuery({clientId: Number(clientid), projectId: Number(productid)});

  const {
    data: projectPages,
    isLoading: projectPagesIsLoading,
    isFetching: projectPagesIsFetching,
    isSuccess: projectPagesIsSuccess,
  } = useGetProjectPagesQuery({clientId: Number(clientid), projectId: Number(productid)});

  const {
    data: tickets,
    isFetching: ticketsIsFetching,
    isLoading: ticketsIsLoading,
    isSuccess: ticketsIsSuccess,
  } = useGetTicketsQuery({projectId: Number(productid), page: pageTickets, query: {...filters}}, {refetchOnMountOrArgChange: true});

  const changePage = (page: number) => {
    setPageTickets(page);
    pageTopRef?.current?.scrollIntoView();
  }

  const onQueryKeyChanged = (e: any) => {
    setFilters({...filters, ...e});
    setPageTickets(1);
  }

  const renderPages = () => {
    return projectPages.data.map((page: any) => {
      return (
        <div className="col-4 col-card" key={`page-${page.id}`}>
          <CardProduct
            image={page.thumbnail_image}
            title={page.page_type.name}
            description={page.title}
            button={<ButtonAction buttonType="link" primary outline light
                                  to={`/${clientid}/projects/${productid}/pages/${page.id}`}>{page.thumbnail_button_label}</ButtonAction>}
          />
        </div>
      )
    })
  }

  return (
    <>
      <Header/>
      {
        (projectIsLoading || projectIsFetching) ? <Loader/> :
          (projectIsSuccess && project.data) ?
            <>
              {
                projects && projects.length >= 2 ?
                  <ButtonBack to={`/${clientid}`} title="Back to Dashboard"/>
                  : null
              }

              <div className={`product-page ${projects && projects.length < 2 ? 'pt-page' : ''}`}>
                <div className="container">
                  <h1 className="title-page">
                    {project.data.name}
                  </h1>
                  {
                    projectPagesIsLoading || projectPagesIsFetching ? <Loader/> :
                      (projectPagesIsSuccess && projectPages.data && projectPages.data.length) ?
                        <CardsList noBorder>
                          <div className="columns">
                            {renderPages()}
                          </div>
                        </CardsList> : null
                  }
                  <div ref={pageTopRef}>
                    <CardsList
                      noBorder
                      title="Support tickets" gapClass
                      button={<ButtonAction primary outline buttonType="link" to={`/${clientid}/projects/${productid}/new-ticket`}>
                        New Ticket</ButtonAction>}>

                      <div className="d-flex justify-content-between">
                        <SortBy selected={filters.order_dir}
                                onFiltersChange={(e) => onQueryKeyChanged({order_dir: e})}>
                          <option value="desc">Date created (newest to oldest)</option>
                          <option value="asc">Date created (oldest to newest)</option>
                        </SortBy>

                        <ButtonAction primary outline={!closedTickets} buttonType="button"
                                      onClick={() => {
                                        onQueryKeyChanged({status: !closedTickets ? "closed" : undefined});
                                        setClosedTickets(!closedTickets)
                                      }}
                        >
                          Closed Tickets
                        </ButtonAction>
                      </div>

                      <div className="tickets-container">
                        {
                          ticketsIsLoading || ticketsIsFetching ? <Loader/> :
                            (ticketsIsSuccess && tickets?.data.length === 0) ?
                              <h4>No ticket found.</h4> :

                              (ticketsIsSuccess && tickets?.data && tickets?.data.length) ?
                                <>
                                  {
                                    tickets?.data.map((ticket) =>
                                      <TicketCard
                                        key={`ticket-${ticket.id}`}
                                        clientId={Number(clientid)}
                                        priority={ticket.priority}
                                        comments={ticket.number_of_comments}
                                        content={ticket.description}
                                        title={ticket.title}
                                        ticketId={ticket.id}
                                      >
                                        <UserProfile
                                          initials={firstLettersInName(ticket.opener)}
                                          email={ticket.opener}
                                          date={new Date(ticket.open_date)}
                                          showTime
                                          status={ticket.status}
                                        />
                                    </TicketCard>)
                                  }
                                  {
                                    tickets?.meta.last_page > 1 ?
                                    <Pagination
                                      pagesLinks={tickets.meta.links}
                                      current_page={tickets.meta.current_page}
                                      setPage={changePage}
                                    /> : null
                                  }
                                </> : null
                        }
                      </div>
                    </CardsList>
                  </div>
                  {
                    project.data.added_extras.length ? <SuperchargeProjects added_extras={project.data.added_extras} /> : null
                  }

                </div>
              </div>
            </> : null
      }
      <Footer/>
    </>
  );
};

export default Product;