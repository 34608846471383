import { useEffect, useRef, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import Modal from 'react-modal';
import * as Yup from 'yup';
import { Form, Formik } from 'formik';
import {
  useAcceptQuoteMutation,
  useDeclineQuoteMutation,
  useGetQuoteQuery,
  useReplyQuoteMutation,
} from '../../services/quotes-api';
import { setMessage } from '../../data-access/message-slice';
import { useAppDispatch } from '../../hooks';

import Header from '../../ui/header/header';
import InputTextarea from '../../ui/input-textarea/input-textarea';
import Loader from '../../ui/loader/loader';

import './quote.scss';

const Quote = () => {
  const {clientid, quoteid} = useParams();
  const [modalOpen, setModalOpen] = useState<boolean | string>(false);
  const iframeRef = useRef<HTMLIFrameElement>(null);
  const navigate = useNavigate();
  const dispatch = useAppDispatch();

  const {
    data: quote,
    isFetching: quoteIsFetching,
    isLoading: quoteIsLoading,
    isSuccess: quoteIsSuccess,
  } = useGetQuoteQuery({clientId: Number(clientid), quoteId: Number(quoteid)});

  const [acceptQuote,
    {
      isSuccess: isAcceptSuccess,
      isLoading: isAcceptLoading,
    },
  ] = useAcceptQuoteMutation();

  const [replyQuote,
    {
      isLoading: isReplyLoading,
      isSuccess: isReplySuccess,
    },
  ] = useReplyQuoteMutation();

  const [declineQuote,
    {
      isLoading: isDeclineLoading,
      isSuccess: isDeclineSuccess,
    },
  ] = useDeclineQuoteMutation();

  useEffect(() => {
    let successMsg = "";
    if (isAcceptSuccess) {
      successMsg = "Quote accepted successfully.";
    }
    if (isReplySuccess) {
      successMsg = "Message sent successfully.";
    }
    if (isDeclineSuccess) {
      successMsg = "Quote declined successfully.";
    }

    if (successMsg) {
      dispatch(setMessage({content: successMsg, type: "success"}));
    }
  }, [isAcceptSuccess, isReplySuccess, isDeclineSuccess])

  const openModal = (modal: string) => {
    setModalOpen(modal);
  }

  const closeModal = () => {
    setModalOpen('')
  }

  Modal.setAppElement('body');


  useEffect(() => {
    let iframe: HTMLIFrameElement;

    if (iframeRef.current) {
      iframe = iframeRef?.current;

      iframe.onload = function () {
        iframe.contentDocument?.body.addEventListener('navigate-back-clicked', (e) => {
          e.preventDefault();
          navigate(`/${clientid}/my-quotes`);
        })

        iframe.contentDocument?.body.addEventListener('quote-accept', () => openModal('accept'))
        iframe.contentDocument?.body.addEventListener('quote-reply', () => openModal('reply'))
        iframe.contentDocument?.body.addEventListener('quote-decline', () => openModal('decline'))

        // Scroll to sections inside iframe when click sidebar links
        if (iframe.contentDocument) {
          const links = iframe.contentDocument.body.querySelectorAll(".navigation-sections a");

          links.forEach(link => {
            link.addEventListener("click", function (e: any) {
              e.preventDefault();
              let str = "";
              if (e.target.tagName  === "STRONG") {
                str = e.target.parentElement.href;
              } else {
                str = e.target?.href;
              }
              const id = str.substring(str.indexOf("#") + 1);
              iframe?.contentDocument?.getElementById(id)?.scrollIntoView({
                block: "start",
                behavior: "smooth",
              });
            })
          })
        }
      }
    }
  })

  useEffect(() => {
    if (isAcceptSuccess || isReplySuccess || isDeclineSuccess) {
      navigate(`/${clientid}/my-quotes`)
    }
  }, [isAcceptSuccess, isReplySuccess, isDeclineSuccess])


  return (
    <>
      <Header/>
      <div className="quote-page">
        {quoteIsLoading || quoteIsFetching ? <Loader/> :
          (quoteIsSuccess && quote) ?
            <>
              <iframe srcDoc={quote} ref={iframeRef} title="Quote" name="This Quote" />

              <Modal
                isOpen={modalOpen === 'accept'}
                onRequestClose={closeModal}
                contentLabel="Accept Quote"
                className="modal modal-quote"
                overlayClassName="modal-overlay"
              >
                <button className="close" onClick={closeModal}></button>
                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 496 512">
                  <path
                    d="M248 8C111 8 0 119 0 256s111 248 248 248 248-111 248-248S385 8 248 8zm0 448c-110.3 0-200-89.7-200-200S137.7 56 248 56s200 89.7 200 200-89.7 200-200 200zm-80-216c17.7 0 32-14.3 32-32s-14.3-32-32-32-32 14.3-32 32 14.3 32 32 32zm160 0c17.7 0 32-14.3 32-32s-14.3-32-32-32-32 14.3-32 32 14.3 32 32 32zm4 72.6c-20.8 25-51.5 39.4-84 39.4s-63.2-14.3-84-39.4c-8.5-10.2-23.7-11.5-33.8-3.1-10.2 8.5-11.5 23.6-3.1 33.8 30 36 74.1 56.6 120.9 56.6s90.9-20.6 120.9-56.6c8.5-10.2 7.1-25.3-3.1-33.8-10.1-8.4-25.3-7.1-33.8 3.1z"/>
                </svg>
                <p>That's great! Please confirm below to proceed with this work and we'll get that
                  booked in for you.</p>
                <div className="btn-wrapper">
                  <button className="btn-quote accept"
                          disabled={isAcceptLoading}
                          onClick={() => acceptQuote({
                            clientId: Number(clientid),
                            quoteId: Number(quoteid),
                          })}>Accept
                  </button>
                </div>
              </Modal>

              <Modal
                isOpen={modalOpen === 'reply'}
                onRequestClose={closeModal}
                contentLabel="Talk More"
                className="modal modal-quote"
                overlayClassName="modal-overlay"
              >
                <button className="close" onClick={closeModal}></button>
                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512">
                  <path
                    d="M192 208c0-17.67-14.33-32-32-32h-16c-35.35 0-64 28.65-64 64v48c0 35.35 28.65 64 64 64h16c17.67 0 32-14.33 32-32V208zm176 144c35.35 0 64-28.65 64-64v-48c0-35.35-28.65-64-64-64h-16c-17.67 0-32 14.33-32 32v112c0 17.67 14.33 32 32 32h16zM256 0C113.18 0 4.58 118.83 0 256v16c0 8.84 7.16 16 16 16h16c8.84 0 16-7.16 16-16v-16c0-114.69 93.31-208 208-208s208 93.31 208 208h-.12c.08 2.43.12 165.72.12 165.72 0 23.35-18.93 42.28-42.28 42.28H320c0-26.51-21.49-48-48-48h-32c-26.51 0-48 21.49-48 48s21.49 48 48 48h181.72c49.86 0 90.28-40.42 90.28-90.28V256C507.42 118.83 398.82 0 256 0z"/>
                </svg>
                <p>We'd be happy to answer any questions you may have on a call. Please choose a
                  time below.</p>
                <Formik
                  initialValues={{
                    message: '',
                  }}
                  validationSchema={
                    Yup.object({
                      message: Yup.string().required('This field is required'),
                    })
                  }
                  onSubmit={async (values, {setSubmitting, resetForm}) => {
                    if (values.message) {
                      await replyQuote({
                        clientId: Number(clientid),
                        quoteId: Number(quoteid),
                        message: values.message,
                      })
                    } else {
                      console.log("Please fill all Inputs")
                    }
                    console.log(isReplySuccess)
                    setSubmitting(false);
                    resetForm();
                  }}
                >
                  {({isSubmitting}) => (
                    <Form autoComplete="off">
                      <InputTextarea
                        name="message"
                        placeholder="Write something here"
                      />
                      <div className="btn-wrapper">
                        <button type="submit"
                                className="btn-quote accept"
                                disabled={isSubmitting || isReplyLoading}>
                          Send message
                        </button>
                      </div>
                    </Form>
                  )}
                </Formik>
              </Modal>

              <Modal
                isOpen={modalOpen === 'decline'}
                onRequestClose={closeModal}
                contentLabel="Reject Quote"
                className="modal modal-quote"
                overlayClassName="modal-overlay"
              >
                <button className="close" onClick={closeModal}></button>
                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 496 512">
                  <path
                    d="M248 8C111 8 0 119 0 256s111 248 248 248 248-111 248-248S385 8 248 8zm0 448c-110.3 0-200-89.7-200-200S137.7 56 248 56s200 89.7 200 200-89.7 200-200 200zm-80-216c17.7 0 32-14.3 32-32s-14.3-32-32-32-32 14.3-32 32 14.3 32 32 32zm160-64c-17.7 0-32 14.3-32 32s14.3 32 32 32 32-14.3 32-32-14.3-32-32-32zm-80 128c-40.2 0-78 17.7-103.8 48.6-8.5 10.2-7.1 25.3 3.1 33.8 10.2 8.4 25.3 7.1 33.8-3.1 16.6-19.9 41-31.4 66.9-31.4s50.3 11.4 66.9 31.4c8.1 9.7 23.1 11.9 33.8 3.1 10.2-8.5 11.5-23.6 3.1-33.8C326 321.7 288.2 304 248 304z"/>
                </svg>
                <p>We're sorry to hear that! So that we can continue improving, would you mind
                  telling
                  us why?</p>
                <Formik
                  initialValues={{
                    message: '',
                  }}
                  validationSchema={
                    Yup.object({
                      message: Yup.string().required('This field is required'),
                    })
                  }
                  onSubmit={async (values, {setSubmitting, resetForm}) => {
                    if (values.message) {
                      await declineQuote({
                        clientId: Number(clientid),
                        quoteId: Number(quoteid),
                        message: values.message,
                      })
                    } else {
                      console.log("Please fill all Inputs")
                    }
                    console.log(isReplySuccess)
                    setSubmitting(false);
                    resetForm();
                  }}
                >
                  {({isSubmitting}) => (
                    <Form autoComplete="off">
                      <InputTextarea
                        name="message"
                        placeholder="Write something here"
                      />
                      <div className="btn-wrapper btns">
                        <button type="submit"
                                className="btn-quote accept"
                                disabled={isSubmitting || isDeclineLoading}>
                          Send message
                        </button>
                        <button type="button"
                                className="btn-link"
                                onClick={() => declineQuote({
                                  clientId: Number(clientid),
                                  quoteId: Number(quoteid),
                                })}
                                disabled={isDeclineLoading}>
                          No thanks
                        </button>
                      </div>
                    </Form>
                  )}
                </Formik>
              </Modal>
            </> : null}
      </div>
    </>
  );
};

export default Quote;