import { useState, useEffect } from 'react';
import { Link, useParams } from 'react-router-dom';

import {
  useConsumeNotificationMutation,
  useGetNotificationsQuery,
} from '../../services/notifications-api';

import Header from '../../ui/header/header';
import ButtonBack from '../../ui/button-back/button-back';
import Loader from '../../ui/loader/loader';
import Card from '../../ui/card/card';
import Footer from '../../ui/footer/footer';

import "./notifications.scss";

const Notifications = () => {
  const {clientid} = useParams();
  const [page, setPage] = useState<number>(1);

  const {
    data: notifications,
    isLoading: notificationsIsLoading,
    isFetching: notificationsIsFetching,
    isSuccess: notificationsIsSuccess,
  } = useGetNotificationsQuery({id: Number(clientid), page})

  const [consumeNotification] = useConsumeNotificationMutation();

  useEffect(() => {
    const onScroll = () => {
      const scrolledToBottom =
        window.innerHeight + window.scrollY + 40 >= document.body.offsetHeight;
      if (scrolledToBottom && !notificationsIsFetching) {
        if (notifications) {
          if (notifications.data.length < notifications.meta.total)
            setPage(page + 1);
        }
      }
    };

    document.addEventListener("scroll", onScroll);

    return function () {
      document.removeEventListener("scroll", onScroll);
    };
  }, [page, notificationsIsFetching]);

  const updateNotification = (id: number) => {
    consumeNotification(id);
  }

  return (
    <>
      <Header/>
      <div className="notifications-page">
        <ButtonBack to={`/${clientid}`} title="Back to Dashboard"/>
        <div className="container">
          <h1>Notifications</h1>
          {(notificationsIsSuccess && !notifications.data) ? <h4 className="empty">No notifications found.</h4>
              : (notificationsIsSuccess && notifications.data) ?
                <div className="tickets-container">
                  {
                    notifications.data.map((notification) =>
                      <Link
                        key={`notification-${notification.id}`}
                        to={`/${clientid}/${notification.url}`}
                        onClick={() => updateNotification(Number(notification.id))}
                        className={`card-notification ${notification.seen_at ? "seen" : ""}`}
                      >
                        <Card>
                          <p className="created_at">{notification.created_at}</p>
                          <p className="message">{notification.message}</p>
                        </Card>
                      </Link>
                    )
                  }
                </div>
              : null}
          {notificationsIsLoading || notificationsIsFetching ? <Loader /> : null}
        </div>
      </div>
      <Footer/>
    </>
  );
};

export default Notifications;