import { ReactNode } from 'react';
import { Field, ErrorMessage, FieldHookConfig, useField } from 'formik';

export interface OtherProps  {
    label?: string;
    description?: string;
    children: ReactNode;
}

const InputSelect = (props : OtherProps & FieldHookConfig<string>) => {
    const [field] = useField(props);
    return (
        <div className="input-field">
            <label htmlFor={props.id || props.name} className="text-md">{props.label}</label>
            {props.description && <p>{props.description}</p>}
            <Field className="text-input" as="select" {...field}>
                {props.children}
            </Field>
            <ErrorMessage className="error-message" component="p" name={props.id || props.name} />
        </div>
    );
};

export default InputSelect;