import "./pagination.scss";

type PageLink = {
  active: boolean;
  label: string;
  url: string;
}

export interface PaginationProps {
  pagesLinks: PageLink[];
  current_page: number;
  setPage: (page: number) => void;
}

const Pagination = ({pagesLinks, current_page, setPage}: PaginationProps) => {
  return (
    <nav>
      <ul className="pagination">
        {pagesLinks.map((page: PageLink, key: number) => (
          !page.url || page.active ?
            <li
              key={`page-item-${key}`}
              className={`page-item ${!page.url ? "disabled" : ""} ${page.active ? "active" : ""}`}>
                                           <span className="page-link">
                                           {key === 0 ?  "<" : key === pagesLinks.length - 1 ? ">" : key}
                                          </span>
            </li>
            : <li key={`page-item-${key}`} className="page-item">
              <button className="page-link" onClick={() => {
                key === 0 ? setPage(current_page - 1) :
                  key === pagesLinks.length - 1 ?
                    setPage(current_page + 1) :
                    setPage(key)
              }}>
                {key === 0 ?  "<" : key === pagesLinks.length - 1 ? ">" : key}
              </button>
            </li>
        ))}
      </ul>
    </nav>
  );
};

export default Pagination;