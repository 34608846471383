import React, { useEffect, useState } from 'react';
import { useAppDispatch } from '../../../../hooks';
import { setMessage } from '../../../../data-access/message-slice';
import { MessageState } from '../../../../data-access/message-slice';
import { useUpdateTicketMutation } from '../../../../services/ticket-api';

import Card from '../../../../ui/card/card';
import ButtonAction from '../../../../ui/button-action/button-action';

import imgOption from '../../../../assets/images/jump-media-group.jpg';
import chromeIcon from '../../../../assets/images/icons/chrome.svg';
import firefoxIcon from '../../../../assets/images/icons/firefox.svg';
import edgeIcon from '../../../../assets/images/icons/edge.svg';
import safariIcon from '../../../../assets/images/icons/safari.svg';
import windowIcon from '../../../../assets/images/icons/window.svg';

import "./ticket-details.scss";

export interface TicketDetailsProps {
  data: {
    id: number;
    status: string;
    priority: string;
    browser: string;
    [k: string]: string | number | null;
  };
}

const TicketDetails = ({data}: TicketDetailsProps) => {
  const [info, setInfo] = useState({
    status: data.status,
    priority: data.priority
  })
  const [statusColor, setStatusColor] = useState("#f50000");
  const [priorityColor, setPriorityColor] = useState("var(--color-medium-gray)");
  const dispatch = useAppDispatch();

  const [updateTicket,
    {
      isSuccess,
      isLoading,
    },
  ] = useUpdateTicketMutation();

  const handleStatusChange = (value: string) => {
    switch (value) {
      case 'new':
        setStatusColor("#f50000");
        break;
      case 'in-progress':
        setStatusColor("var(--color-blue)");
        break;
      case 'blocked':
        setStatusColor("#7f25a2");
        break;
      case 'closed':
        setStatusColor("#6bc950");
        break;
    }
  }

  const handlePriorityChange = (value: string) => {
    switch (value) {
      case 'low':
        setPriorityColor("var(--color-medium-gray)");
        break;
      case 'high':
        setPriorityColor("var(--color-yellow)");
        break;
      case 'urgent':
        setPriorityColor("#f50000");
        break;
    }
  }

  const browserIcon = (browser: string) => {
    let icon = windowIcon;
    let browser_lowercase = browser.toLowerCase();

    if (browser_lowercase.includes("chrome")) {
      icon = chromeIcon;
    } else if (browser_lowercase.includes("firefox")) {
      icon = firefoxIcon;
    } else if (browser_lowercase.includes("edge")) {
      icon = edgeIcon;
    } else if (browser_lowercase.includes("safari")) {
      icon = safariIcon;
    }

    return icon;
  }

  useEffect(() => {
    handlePriorityChange(data.priority);
    handleStatusChange(data.status);
  }, [])

  useEffect(() => {
    let message: MessageState["message"] = {
      content: "",
      type: "error"
    };

    if (isSuccess) {
      message = {
        content: "Ticket updated successfully.",
        type: "success"
      }
    }

    dispatch(setMessage(message));
  }, [isSuccess])

  return (
    <Card>
      <div className="ticket-options">
        <img src={imgOption} alt=""/>
        <ButtonAction primary buttonType="button" gray>Session Info</ButtonAction>
        <div className="option align-items-center">
          <span className="name">Status:</span>
          <div className="input-field">
            <select disabled={isLoading}
                    className="text-input colored-select"
                    name="status"
                    value={info.status}
                    onChange={(e: React.ChangeEvent<any>) => {
                      handleStatusChange(e.target.value);
                      updateTicket({ticketId: data.id, data: {"status": e.target.value}});
                      setInfo({...info, status: e.target.value});
                    }}>
              <option value="new">New</option>
              <option value="in-progress">In progress</option>
              <option value="blocked">Blocked</option>
              <option value="closed">Closed</option>
            </select>
          </div>
          <span style={{"--select-color": statusColor} as React.CSSProperties}
                className="select-color"/>
        </div>

        <div className="option align-items-center">
          <span className="name">Priority:</span>
          <div className="input-field">
            <select disabled={isLoading}
                    className="text-input colored-select"
                    name="priority"
                    value={info.priority}
                    onChange={(e: React.ChangeEvent<any>) => {
                      handlePriorityChange(e.target.value);
                      updateTicket({ticketId: data.id, data: {"priority": e.target.value}});
                      setInfo({...info, priority: e.target.value});
                    }}>
              <option value="low">Low</option>
              <option value="high">High</option>
              <option value="urgent">Urgent</option>
            </select>
          </div>
          <span style={{"--select-color": priorityColor} as React.CSSProperties}
                className="select-color"/>
        </div>
        {
          data.browser ?
            <div className="d-flex flex-col bt">
              <div className="option align-items-start pt-4">
                <span className="name">Browser:</span>
                <span>
                  <img className="xl-icon mr-1" src={browserIcon(data.browser)} alt=""/>
                  {data.browser}</span>
              </div>
              <div className="option align-items-start">
                <span className="name">Window size:</span>
                <span>{data.window_size}</span>
              </div>
              <div className="option align-items-start">
                <span className="name">Resolution:</span>
                <span>{data.screen_resolution}</span>
              </div>
              <div className="option align-items-start">
                <span className="name">DPI:</span>
                <span>{data.dpi}</span>
              </div>
              <div className="option align-items-start">
                <span className="name">Color depth:</span>
                <span>{data.colour_depth}</span>
              </div>
            </div> : null
        }
      </div>
    </Card>
  );
};

export default TicketDetails;