import { useEffect, useState } from 'react';
import { Link, useNavigate, useParams } from 'react-router-dom';

import { useAppDispatch, useAppSelector } from '../../hooks';
import {
    logout,
    selectClientOnboardingLevelTooSmall,
    selectUserOnboardingLevelTooSmall,
} from '../../data-access/auth-slice';
import { selectUser } from '../../data-access/auth-slice';
import {
    selectClient,
    selectClientId,
    selectProjects,
    selectQuotes,
    setClient,
    setClientId,
} from '../../data-access/client-slice';
import { Project } from '../../services/projects-api';

import './header.scss';
import { useGetClientQuery } from '../../services/client-api';

const Header = () => {
    const user = useAppSelector(selectUser);
    const clients = user?.clients;
    const clientId = useAppSelector(selectClientId);
    const clientName = useAppSelector(selectClient)?.name;
    const quotes = useAppSelector(selectQuotes);
    const projects = useAppSelector(selectProjects);
    const clientOnboardingLevelTooSmall = useAppSelector(selectClientOnboardingLevelTooSmall);
    const userOnboardingLevelTooSmall = useAppSelector(selectUserOnboardingLevelTooSmall);

    const dispatch = useAppDispatch();
    const navigate = useNavigate();
    const { clientid } = useParams();

    const [showMenu, setShowMenu] = useState<boolean>(false);

    const {
        data: client,
        refetch: refetchClient,
    } = useGetClientQuery(clientId, {skip: !(!!clientId) || clientOnboardingLevelTooSmall || userOnboardingLevelTooSmall});

    const handleLogout = () => {
        dispatch(logout());
        localStorage.removeItem("token");
        navigate('/login', {replace: true})
    }

    useEffect(() => {
        if (user) {
            dispatch(setClient(clients?.find((client) => client.id === Number(clientid))));
        }
        if (clientid && Number(clientid) !== clientId) {
            dispatch(setClientId(Number(clientid)));
        } else if (!clientid) {
            if (user && user.clients.length > 0) {
                const firstClient = user?.clients[0].id;
                dispatch(setClientId(firstClient));
                navigate(`/${firstClient}`, {replace: true})
            }
        } else if(!clientOnboardingLevelTooSmall && !userOnboardingLevelTooSmall) {
            refetchClient();
        }
    }, [clientId, clientid, user, clientOnboardingLevelTooSmall, userOnboardingLevelTooSmall])

    const handleSelectClient = (clientId: number) => {
        dispatch(setClientId(clientId));
        navigate(`/${clientId}`);
        // dispatch(setClient(clients?.find((client) => client.id === clientId)));
    };

    const renderClients = () => {
        if (user && clients && clients.length > 1) {
            return clients.map((client) => {
                return (
                  <option key={client.id} value={client.id}>{client.name}</option>
                )
            })
        }
    }

    return (
      <div className="main-header">
          <div className="navbar">
              <div className="nav-logos">
                  <img src={require("../../assets/images/jump-logo.png")} alt=""/>
                  <span>x</span>

                  {client?.data.logo
                    ? <div className="logo" style={{
                        backgroundImage: `url(${process.env.REACT_APP_URL}${client.data.logo})`
                    }}></div>

                    : <p className="client-name">{clientName}</p>
                  }
              </div>
              <div className="nav-links">
                  { user && clients && clientId && clients.length > 1 &&
                    <select
                      className="select_client"
                      name="select_client"
                      value={clientId}
                      onChange={(e) => handleSelectClient(Number(e.target.value))}
                    >
                        { renderClients() }
                    </select>
                  }
                  {
                      client?.data.notifications_count ?
                      <Link className="nav-link notifications-link" to={`/${clientid}/notifications`}>
                          My notifications
                          <span className="badge">{client.data.notifications_count}</span>
                      </Link>
                    : null
                  }
                  <p className="nav-link" onClick={() => setShowMenu(!showMenu)}>My Account</p>
              </div>
          </div>
          <div className={`offcanvas-menu ${showMenu ? 'show' : ''}`}>
              <p className="hide-btn text-md" onClick={() => setShowMenu(!showMenu)}>
                  Hide
                  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512" fill="#000">
                      <path d="M190.5 66.9l22.2-22.2c9.4-9.4 24.6-9.4 33.9 0L441 239c9.4 9.4 9.4 24.6 0 33.9L246.6 467.3c-9.4 9.4-24.6 9.4-33.9 0l-22.2-22.2c-9.5-9.5-9.3-25 .4-34.3L311.4 296H24c-13.3 0-24-10.7-24-24v-32c0-13.3 10.7-24 24-24h287.4L190.9 101.2c-9.8-9.3-10-24.8-.4-34.3z"/>
                  </svg>
              </p>

              <div className="menu-links">
                  <div className="show-mobile">
                      {
                          client?.data.notifications_count ?
                          <Link className="nav-link notifications-link" to={`/${clientid}/notifications`}>
                              My notifications
                              <span className="badge">{client.data.notifications_count}</span>
                          </Link>
                        : null}
                      { user && clients && clientId && clients.length > 1 &&
                        <select
                          className="select_client"
                          name="select_client"
                          value={clientId}
                          onChange={(e) => handleSelectClient(Number(e.target.value))}
                        >
                            { renderClients() }
                        </select>
                      }
                  </div>
                  {projects && projects.length > 0 ?
                    <>
                        <h4>
                            <Link to={`/${clientid}/my-projects`}>
                                My Projects
                            </Link>
                        </h4>
                        {
                            projects.map((project: Project, key) =>
                              key < 3 ?
                              <p className="text-md" key={project.id}>
                                  <Link to={`/${clientid}/projects/${project.id}`}>{project.name}</Link>
                              </p>
                                : null
                            )
                        }
                        {projects.length > 3 ?
                          <Link className="underline" to={`/${clientid}/my-projects`}>
                            See all projects
                        </Link> : null}
                    </>
                    : null}

                  {quotes && Object.keys(quotes).length > 0 ?
                    <>
                        <h4>
                            <Link to={`/${clientid}/my-quotes`}>
                                My Quotes
                            </Link>
                        </h4>
                        {
                            Object.values(quotes).flat().map((quote, key) =>
                                key < 3 ?
                                <p className="text-md" key={quote.id}>
                                    <Link to={`/${clientid}/my-quotes/${quote.id}`}>{quote.name}</Link>
                                </p> : null
                            )
                        }
                        {Object.values(quotes).flat().length > 3 ?
                          <Link className="underline" to={`/${clientid}/my-quotes`}>
                            See all quotes
                        </Link> : null}
                    </>: null}

                  <h4 onClick={() => handleLogout()} className="logout">Logout</h4>
              </div>
          </div>
      </div>
    );
};

export default Header;
