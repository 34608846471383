import React, { ReactNode, useEffect, useState } from 'react';
import * as Yup from 'yup';
import { Form, Formik } from 'formik';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faReply } from '@fortawesome/free-solid-svg-icons';

import { setMessage } from '../../data-access/message-slice';
import { useAppDispatch } from '../../hooks';
import { usePostTicketCommentMutation } from '../../services/ticket-api';

import ButtonWithIcon from '../button-with-icon/button-with-icon';
import UserProfile from '../user-profile/user-profile';
import Card from '../card/card';
import InputTextarea from '../input-textarea/input-textarea';
import FilesUpload from '../files-upload/files-upload';
import ButtonAction from '../button-action/button-action';

import './ticket-comment.scss';

export interface TicketCommentProps {
  id: number;
  profileIcon?: string;
  initials?: string;
  date: Date;
  ticketId: number;
  reply?: () => void;
  comment: string | ReactNode;
  children?: ReactNode;
}

const TicketComment = ({profileIcon, initials, date, reply, ticketId, id, comment, children, }: TicketCommentProps) => {
  const [filesBlob, setFilesBlob] = useState<FileList | null | Array<any>>(null);
  const [resetFiles, setResetFiles] = useState<boolean>(false);
  const [showCommentForm, setShowCommentForm] = useState<boolean>(false);
  const dispatch = useAppDispatch();

  const [newTicketComment, {
    isSuccess: isNewTicketCommentSuccess,
    isLoading: isNewTicketCommentLoading,
  }] = usePostTicketCommentMutation();

  useEffect(() => {
    if (isNewTicketCommentSuccess) {
      dispatch(setMessage({
        content: "Comment added successfully.",
        type: "success"
      }));
    }
  }, [isNewTicketCommentSuccess])

  return (
    <div className="ticket-comment">
      <UserProfile profileIcon={profileIcon} initials={initials} date={date} showTime />

      <div className="card-wrapper">
        <Card>
          {comment}
          {reply ? <div className="reply-button">
            <ButtonWithIcon
              title="Reply"
              icon={<FontAwesomeIcon icon={faReply} />}
              action={() => setShowCommentForm(!showCommentForm)}
              color="#2878F0" />
          </div> : null}
        </Card>
      </div>

      {children}

      {reply ?
      <Formik
        initialValues={{
          comment: ''
        }}
        validationSchema={
          Yup.object({
            comment: Yup.string().max(65535, "The comment cannot have more than 65,535 characters")
              .required('This field is required'),
          })
        }
        onSubmit={async (values, { setSubmitting, resetForm }) => {
          try {
            const ticketObject: any = {
              parent_id: id,
              body: values.comment,
              attachments: filesBlob,
            }

            const formData = new FormData();
            Object.keys(ticketObject).forEach(key => {
              if (key === 'attachments' && ticketObject.attachments) {
                ticketObject[key].forEach((file: any) => formData.append("attachments[]", file));
              } else {
                formData.append(key, ticketObject[key]);
              }
            });

            await newTicketComment({ticketId: ticketId, data: formData})
              .unwrap()
              .then(() => {
                setSubmitting(false);
                resetForm();
                setResetFiles(true);
                reply();
                setShowCommentForm(false);
              })
          } catch (error: any) {
            console.error('Failed to save the post: ', error)
            setSubmitting(false);
          }
        }}>
        {({ isSubmitting }) => (
          <Form autoComplete="off" className={`form-comment ${showCommentForm ? 'show' : ''}`}>
            <div className="input-wrapper">
              <InputTextarea
                label="Comment"
                name="comment"
                id="comment"
              />
            </div>
            <div className="input-wrapper">
              <FilesUpload
                id={id}
                label="Files"
                reset={resetFiles}
                uploadFilesHandler={(files: FileList | null | Array<any>) => setFilesBlob(files) }/>
            </div>
            <ButtonAction
              buttonType="button"
              type="submit"
              primary
              disabled={isSubmitting || isNewTicketCommentLoading}
            >
              Reply
            </ButtonAction>
          </Form>
        )}
      </Formik> : null}
    </div>
  );
};

export default TicketComment;