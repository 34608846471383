import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { SwiperSlide } from 'swiper/react';
import { useLazyGetQuotesQuery } from '../../services/quotes-api';
import { QuoteType } from '../../types/quotes';

import Header from '../../ui/header/header';
import Footer from '../../ui/footer/footer';
import ButtonBack from '../../ui/button-back/button-back';
import ButtonAction from '../../ui/button-action/button-action';
import SwiperCards from '../../ui/swiper-cards/swiper-cards';
import CardProduct from '../../ui/card-product/card-product';
import CardsList from '../../ui/cards-list/cards-list';
import Loader from '../../ui/loader/loader';
import SortBy from '../../ui/sort-by/sort-by';

import './quotes.scss';
export interface SortType {
  order_by: string;
  order_dir: string;
}

const Quotes = () => {
  const [filters, setFilters] = useState<SortType>({order_by: "created_at", order_dir: "desc"});
  const {clientid} = useParams();
  let quotesOrder = {
    Pending: null,
    Accepted: null,
    Declined: null,
  }

  const [getQuotes, {
    data: quotes,
    isFetching: quotesIsFetching,
    isLoading: quotesIsLoading,
    isSuccess: quotesIsSuccess,
  }] = useLazyGetQuotesQuery();

  useEffect(() => {
    if (clientid) {
      getQuotes({id: Number(clientid), query: {...filters}});
    }
  }, [filters]);

  const onQueryKeyChanged = (e: any) => {
    setFilters({...filters, ...e})
  }

  return (
    <>
      <Header/>
      <div className="page-quotes">
        <ButtonBack to={`/${clientid}`} title="Back to Dashboard"/>
        <div className="container">
          <h1 className="mb-0">My Quotes</h1>
          {quotesIsLoading || quotesIsFetching ? <Loader/> :
            quotesIsSuccess && Boolean(quotes) ? (
              quotes.length === 0
                ? <h4 className="empty">No quote found.</h4>
                : Object.keys(Object.assign(quotesOrder, quotes)).map((key, index) => (
                  quotes[key] ?
                    <CardsList
                      key={`card-list-${index}`}
                      title={key}
                      button={index === 0 ?
                        <SortBy selected={filters.order_dir}
                                onFiltersChange={(e) => onQueryKeyChanged({order_dir: e})}>
                          <option value="desc">Date created (newest to oldest)</option>
                          <option value="asc">Date created (oldest to newest)</option>
                        </SortBy> : null}
                    >
                      <SwiperCards
                        spaceBetween={15}
                        slidesPerView={1}
                        breakpoints={{
                          576: {
                            slidesPerView: 2,
                          },
                          992: {
                            slidesPerView: 3,
                          },
                          1200: {
                            slidesPerView: 4,
                          },
                          1600: {
                            slidesPerView: 5,
                          },
                        }}>
                        {quotes[key].map((quote: QuoteType) => (
                          <SwiperSlide key={quote.id}>
                            <CardProduct
                              title={quote.name}
                              button={
                                <ButtonAction buttonType="link"
                                              primary outline light
                                              to={`/${clientid}/my-quotes/${quote.id}`}>
                                  View project
                                </ButtonAction>}
                            />
                          </SwiperSlide>
                        ))}
                      </SwiperCards>
                    </CardsList> : null
                ))
            ) : null
            // (quotesIsSuccess && !quotes) ?
            //   <h4 className="empty">No quote found.</h4>
            //   : (quotesIsSuccess && quotes?.length > 0) ?
            //     Object.keys(Object.assign(quotesOrder, quotes)).map((key, index) => {
            //       return (
            //         <CardsList
            //           key={`card-list-${index}`}
            //           title={key}
            //           button={index === 0 ?
            //             <SortBy selected={filters.order_dir}
            //                     onFiltersChange={(e) => onQueryKeyChanged({order_dir: e})}>
            //               <option value="desc">Date created (newest to oldest)</option>
            //               <option value="asc">Date created (oldest to newest)</option>
            //             </SortBy> : null}
            //         >
            //           <SwiperCards
            //             spaceBetween={15}
            //             slidesPerView={1}
            //             breakpoints={{
            //               576: {
            //                 slidesPerView: 2,
            //               },
            //               992: {
            //                 slidesPerView: 3,
            //               },
            //               1200: {
            //                 slidesPerView: 4,
            //               },
            //               1600: {
            //                 slidesPerView: 5,
            //               },
            //             }}>
            //             {quotes[key].map((quote: QuoteType) => (
            //               <SwiperSlide key={quote.id}>
            //                 <CardProduct
            //                   title={quote.name}
            //                   button={
            //                     <ButtonAction buttonType="link"
            //                                   primary outline light
            //                                   to={`/${clientid}/my-quotes/${quote.id}`}>
            //                       View project
            //                     </ButtonAction>}
            //                 />
            //               </SwiperSlide>
            //             ))}
            //           </SwiperCards>
            //         </CardsList>
            //       )
            //     })
            //     : null
          }
        </div>
      </div>
      <Footer/>
    </>

  );
};

export default Quotes;