import React from "react";
import { Navigate } from 'react-router-dom';
import { useAppSelector } from '../hooks';
import { selectIsAuthenticated, selectToken } from '../data-access/auth-slice';

interface AuthGuardType {
  children: React.ReactNode;
}

const AuthGuard = ({children}: AuthGuardType) => {
  const token = useAppSelector(selectToken);
  const isAuthenticated = useAppSelector(selectIsAuthenticated)

  if (!token) {
    if (!isAuthenticated) {
      return <Navigate to='/login' replace/>
    }
  }

  return (
    <>{children}</>
  );
};

export default AuthGuard;