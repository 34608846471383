import { ReactNode } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowDownShortWide } from '@fortawesome/free-solid-svg-icons';

import './sort-by.scss';

export interface PropsSortBy {
  onFiltersChange: (e: any) => void;
  selected: string;
  children: ReactNode;
}

const SortBy = ({onFiltersChange, selected, children}: PropsSortBy) => {
  return (
    <div className="sort-by">
      <FontAwesomeIcon icon={faArrowDownShortWide} />
      <label htmlFor="sort">Sort by:</label>
      <select id="sort" defaultValue={selected} onChange={(e) => onFiltersChange(e.target.value)}>
        {children}
      </select>
    </div>
  );
};

export default SortBy;