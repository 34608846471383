import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { RootState } from '../store';
import { logout } from './auth-slice';

export type MessageState = {
  message: {
    content: string;
    type: "error" | "success";
  };
};

const initialState: MessageState = {
  message: {
    content: "",
    type: "success",
  },
};

const messageSlice = createSlice({
  name: "message",
  initialState,
  reducers: {
    setMessage: (state, action: PayloadAction<MessageState["message"]>) => {
      state.message = action.payload;
    },
    clearMessage: (state) => {
      state.message = initialState.message;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(logout, () => {
      return initialState
    })
  }
});

const { reducer, actions } = messageSlice;

export const { setMessage, clearMessage } = actions;

export const selectMessage = (state: RootState) => state.message.message;

export default reducer;
