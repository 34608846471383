export const fileExtensions = ".jpg, .jpeg, .png"
export const fileTypes = ["image/jpeg", "image/png"]

export const filesizeChecker = (fileArray: any) => {
    let error = '';
    if(fileArray.length > 2) {
        error = "You can't upload more than 2 files"
    }
    for(const file of fileArray) {
        let fileSize = file.size/1024
        if (fileSize > 2048) {
            error = "Uploaded files can't be bigger than 2MB each."
        } else if(!fileTypes.includes(file.type) && !file.name.endsWith('.msg')) {
            error = `Uploaded files must be of the type ${fileExtensions}`
        }
    }

    return error;
}

export const formatBytes = (bytes: number, decimals: number = 2) => {
    if (bytes === 0) return '0 Bytes';

    const k = 1024;
    const dm = decimals < 0 ? 0 : decimals;
    const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];

    const i = Math.floor(Math.log(bytes) / Math.log(k));

    return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + ' ' + sizes[i];
}

export const firstLettersInName = (name: string) => {
    if (name) {
        return name.split(" ")
          .map(word => word[0])
          .slice(0, 2)
          .join('');
    }
}