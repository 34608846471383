import {ReactNode} from "react";
import { Link } from 'react-router-dom';

import { faFlag } from "@fortawesome/free-solid-svg-icons";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import { faCommentDots, faCircle, faReply } from "@fortawesome/free-solid-svg-icons";

import "./ticket-card.scss";

export interface TicketCardProps {
    clientId: number;
    content?: string;
    comments?: string | number;
    priority: "low" | "high" | "urgent";
    ticketId: number;
    title?: string;
    reply?: boolean;
    children?: ReactNode;
}

function TicketCard({children, clientId, reply, content, comments, priority = "low", ticketId, title}:TicketCardProps) {
    return (
      <Link to={`/${clientId}/ticket/${ticketId}`} className="ticket-card">
            {children}
            {content && <p className="content">
                {content}
            </p>
            }
            <div className="ticket-card-footer">
                {reply ? <span className="reply pt-4"><FontAwesomeIcon icon={faReply} />Reply</span> : null}
                <FontAwesomeIcon icon={faFlag} className={`icon-flag ${priority}`} />
                {title ? <span className="ticket-id">{title}</span> : null}
                {comments && comments > 0 ?
                <div className="comment-container">
                    <FontAwesomeIcon icon={faCircle} className="icon-dot" />
                    <FontAwesomeIcon icon={faCommentDots} className="icon-message" />
                    <span className="comment">
                        {comments}
                    </span>
                </div> : null}
            </div>
        </Link>
    );
}

export default TicketCard;
