import { useEffect, useRef } from 'react';
import { useParams } from 'react-router-dom';
import { useGetPageQuery } from '../../services/page-api';
import { useAppSelector } from '../../hooks';
import { selectClientId } from '../../data-access/client-slice';

import Header from '../../ui/header/header';
import Footer from '../../ui/footer/footer';
import ButtonBack from '../../ui/button-back/button-back';
import Loader from '../../ui/loader/loader';

import './project-page.scss';

const ProjectPage = () => {
  const clientId = useAppSelector(selectClientId);
  const {clientid, productid, pageid} = useParams();
  const iframeRef = useRef<HTMLIFrameElement>(null);

  const {
    data: pageData,
    isLoading,
  } = useGetPageQuery({clientId: Number(clientid), projectId: Number(productid), pageId: Number(pageid)});

  useEffect(() => {
    let iframe: HTMLIFrameElement;
    let cssLink = document.createElement("link");
    cssLink.href = `${process.env.PUBLIC_URL}/ck-styles.css`;
    cssLink.rel = "stylesheet";
    cssLink.type = "text/css";

    if (iframeRef.current) {
      iframe = iframeRef?.current;

      iframe.onload = function () {
        iframe.contentDocument?.head.appendChild(cssLink)
      }
    }
  })

  return (
    <>
      <Header />
      <ButtonBack to={`/${clientId}`} title="Back to Dashboard"/>
      <div className="project-page-page">
        {
          isLoading ? <Loader/> :
            <div className="container">
              <h1>{pageData.data.title}</h1>
              <iframe srcDoc={pageData.data.html} ref={iframeRef} title={pageData.data.title} />
            </div>
        }
      </div>
      <Footer />
    </>
  );
};

export default ProjectPage;