import { ComponentProps, ReactNode } from 'react';
import { Link, LinkProps } from 'react-router-dom';

import './button-action.scss';

export interface ButtonActionCommonProps {
    outline?: boolean;
    children: ReactNode;
    primary: boolean;
    light?: boolean;
    gray?: boolean;
    text?: boolean;
    color?: "error" | "success";
}

export interface ButtonActionProps extends ComponentProps<"button"> {
    buttonType: "button";
}

export interface LinkActionProps extends LinkProps {
    buttonType: "link";
}

export interface ExternalLinkActionProps extends ComponentProps<"a"> {
    buttonType: "external_link";
}

export type Props = ButtonActionCommonProps &
    (ButtonActionProps | LinkActionProps | ExternalLinkActionProps);

const ButtonAction = ({
    buttonType,
    outline,
    light,
    text,
    children,
    gray,
    primary,
    color,
    ...restProps
    }: Props) => {
    const BUTTON_LINK_CLASS = primary
        ? "button-primary text-md" + (outline ? " outline" : "") + (light ? " light" : "") + (gray ? " grey" : "") + (text ? " text" : "") +  (color ? ` ${color}` : "")
        : "button-secondary";

    return buttonType === "link" ? (
        <Link {...(restProps as LinkProps)} className={BUTTON_LINK_CLASS}>
            {children}
        </Link>
    ) :
      buttonType === "external_link" ? (
      <a {...(restProps as ComponentProps<"a">)} className={BUTTON_LINK_CLASS}>
          {children}
      </a> )
        : (
        <button
            {...(restProps as ComponentProps<"button">)}
            className={BUTTON_LINK_CLASS}
        >
            {children}
        </button>
    );
};

export default ButtonAction;
