// @ts-ignore
import { createApi } from '@reduxjs/toolkit/dist/query/react';
// @ts-ignore
import baseQueryWithErrorCheck from './base-query';
import { NotificationType } from '../types/notification';
import { ListResponse } from '../types/list-response';

export const notificationsApi = createApi({
  reducerPath: "notificationsApi",
  tagTypes: ['Notifications'],
  baseQuery: baseQueryWithErrorCheck,
  endpoints: (builder) => ({
    getNotifications: builder.query<ListResponse<NotificationType>, {id: number, page: number}>({
      query: ({id, page}: {id: number, page: number}) => {
        return {
          url: `/${id}/notifications?page=${page}`,
          method: "get",
        }
      },
      serializeQueryArgs: ({ endpointName }) => {
        return endpointName
      },
      merge: (currentCache, newItems) => {
        currentCache.data.push(...newItems.data);
        currentCache.meta = newItems.meta;
        currentCache.links = newItems.links;
      },
      forceRefetch({ currentArg, previousArg }) {
        return JSON.stringify(currentArg) !== JSON.stringify(previousArg);
      },
      providesTags: ["Notifications"],
    }),
    consumeNotification: builder.mutation({
      query: (id: number) => {
        return {
          url: `/notifications/${id}/consume`,
          method: "post",
          body: { _method: 'patch' }
        }
      },
      invalidatesTags: ["Notifications"]
    }),
  })
})

export const { useGetNotificationsQuery, useConsumeNotificationMutation } = notificationsApi;