import { useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { Form, Formik } from 'formik';
import * as Yup from 'yup';
import { useAppDispatch } from '../../../hooks';
import {
  useRegisterUserMutation,
  useGetInviteCodeQuery,
  useLoginUserMutation,
} from '../../../services/auth-api';
import { FetchBaseQueryErrorCustom } from "../../../types/general";
import { setAuthToken } from "../../../data-access/auth-slice";

import ButtonAction from '../../../ui/button-action/button-action';
import InputText from '../../../ui/input-text/input-text';
import Loader from "../../../ui/loader/loader";

import "./invite.scss";

const Invite = () => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const {code} = useParams();
  const phoneRegExp = /^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/

  const validationSchema = Yup.object({
    email: Yup.string()
      .email('Invalid email address')
      .required('Email is required'),
    password: Yup.string()
      .min(6, 'Password is too short - should be 8 chars minimum.')
      .required('No password provided.'),
    confirm_password: Yup.string()
      .required('Confirm password is required')
      .oneOf([Yup.ref('password'), null], 'Passwords must match'),
    name: Yup.string()
      .required('Name is required'),
    phone: Yup.string().matches(phoneRegExp, 'Phone number is not valid'),
    job_title: Yup.string(),
    show_client_fields: Yup.boolean(),
    client_name: Yup.string()
      .when(['show_client_fields', 'client_reg_number', 'client_reg_address', 'client_email'], {
        is: (showClient: boolean, regNumber: string, regAddress: string, email: string) => {
          return showClient && (regNumber || regAddress || email);
        },
        then: Yup.string().required('Name is required'),
      }),
    client_reg_number: Yup.string()
      .when('show_client_fields', {
        is: true,
        then: Yup.string(),
      }),
    client_reg_address: Yup.string()
      .when('show_client_fields', {
        is: true,
        then: Yup.string(),
      }),
    client_email: Yup.string()
      .email('Invalid email address')
      .when('show_client_fields', {
        is: true,
        then: Yup.string(),
      }),
  });

  const {
    data: inviteData,
    isLoading: isInviteLoading,
    isFetching: isInviteFetching,
    isSuccess: isInviteSuccess,
    isError: isInviteError,
    error: inviteError
  } = useGetInviteCodeQuery(String(code), {skip: !code});

  const [registerUser] = useRegisterUserMutation();

  const [loginUser,
    {
      data: loginData,
      isSuccess: isLoginSuccess,
    }
  ] = useLoginUserMutation();

  useEffect(() => {
    if (isLoginSuccess) {
      localStorage.setItem("token", loginData.data.token);

      navigate('/');

      dispatch(
        setAuthToken({
          token: loginData.data.token
        })
      );
    }
  }, [isLoginSuccess]);

  return (
    <div className="login-page">
      {
        isInviteLoading || isInviteFetching ? (
          <Loader/>
        ) : isInviteError ? (
            <div className="container">
              <img className="logo-jump" src={require("../../../assets/images/jump-logo-white.png")} alt=""/>
              <div className="auth-form-container">
                <h2 style={{marginBottom: 0}}>
                  {(inviteError as FetchBaseQueryErrorCustom)?.data?.message
                    ?? "An error occurred!"}
                </h2>
              </div>
            </div>
          ) : (
          isInviteSuccess && inviteData.data ? (
            <div className="container">
              <img className="logo-jump" src={require("../../../assets/images/jump-logo-white.png")} alt=""/>
              <div className="auth-form-container">
                <Formik
                  initialValues={{
                    email: inviteData.data.email,
                    password: '',
                    confirm_password: '',
                    name: '',
                    phone: '',
                    job_title: '',
                    show_client_fields: inviteData.data.minimum_onboarding_level === 2,
                    client_name: '',
                    client_reg_number: '',
                    client_reg_address: '',
                    client_email: '',
                  }}
                  validationSchema={validationSchema}
                  onSubmit={async (values, { setSubmitting, resetForm, setFieldValue }) => {
                    try {
                      const registerObject = {
                        code: String(code),
                        onboarding_level: inviteData.data.minimum_onboarding_level,
                        email: values.email,
                        password: values.password,
                        password_confirmation: values.confirm_password,
                        name: values.name,
                        phone: values.phone,
                        job_title: values.job_title,
                        ...(values.client_name && {
                          client: {
                            name: values.client_name,
                            reg_number: values.client_reg_number,
                            reg_address: values.client_reg_address,
                            email: values.client_email,
                          },
                        }),
                      }

                      await registerUser(registerObject)
                        .unwrap()
                        .then(() => {
                          setSubmitting(false);

                          loginUser({email: values.email, password: values.password});

                          resetForm();
                          setFieldValue('client_name', '');
                          setFieldValue('client_reg_number', '');
                          setFieldValue('client_reg_address', '');
                          setFieldValue('client_email', '');
                        })
                    } catch (error: any) {
                      console.log(error);
                      setSubmitting(false);
                    }
                  }}
                >
                  {({ isSubmitting, values }) => (
                    <Form autoComplete="off">
                      <h2>Provide your information</h2>
                      <div className="input-wrapper">
                        <InputText
                          label="Email"
                          name="email"
                          type="email"
                          required
                          disabled={!!values.email}
                        />
                      </div>
                      <div className="input-wrapper">
                        <InputText
                          label="Password"
                          name="password"
                          type="password"
                          required
                        />
                      </div>
                      <div className="input-wrapper">
                        <InputText
                          label="Confirm Password"
                          name="confirm_password"
                          type="password"
                          required
                        />
                      </div>
                      <div className="input-wrapper">
                        <InputText
                          label="Name"
                          name="name"
                          type="text"
                          required
                        />
                      </div>
                      <div className="input-wrapper">
                        <InputText
                          label="Phone"
                          name="phone"
                          type="tel"
                        />
                      </div>
                      <div className="input-wrapper">
                        <InputText
                          label="Job title"
                          name="job_title"
                          type="text"
                        />
                      </div>
                      {inviteData.data.minimum_onboarding_level === 2 &&
                        <>
                          <div className="input-wrapper">
                            <InputText
                              label="Client name"
                              name="client_name"
                              type="text"
                              required={!!(values.client_reg_number || values.client_reg_address || values.client_email)}
                            />
                          </div>
                          <div className="input-wrapper">
                            <InputText
                              label="Client registration number"
                              name="client_reg_number"
                              type="text"
                            />
                          </div>
                          <div className="input-wrapper">
                            <InputText
                              label="Client registration address"
                              name="client_reg_address"
                              type="text"
                            />
                          </div>
                          <div className="input-wrapper">
                            <InputText
                              label="Client email"
                              name="client_email"
                              type="email"
                            />
                          </div>
                        </>
                      }
                      <ButtonAction
                        buttonType="button"
                        type="submit"
                        primary
                        disabled={isSubmitting}
                      >
                        Register
                      </ButtonAction>
                    </Form>
                  )}
                </Formik>
              </div>
            </div>
          ) : null
        )
      }
    </div>
  );
};

export default Invite;