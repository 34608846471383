// @ts-ignore
import { createApi } from '@reduxjs/toolkit/dist/query/react';
// @ts-ignore
import baseQueryWithErrorCheck from './base-query';

export interface Project {
    id: number
    name: string
}

export interface ListProjects<T> {
    data: T[]
}

export const projectsApi = createApi({
    reducerPath: "projectsApi",
    baseQuery: baseQueryWithErrorCheck,
    endpoints: (builder) => ({
        getProjects: builder.query<ListProjects<Project>, number>({
            query: (id) => {
                return {
                    url: `/${id}/projects`,
                    method: "get"
                }
            },
        }),
    }),
})

export const { useGetProjectsQuery } = projectsApi;