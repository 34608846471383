import { ReactNode } from 'react';

import './cards-list.scss';

export interface CardsListProps {
    title?: string;
    button?: ReactNode | null;
    children: ReactNode;
    noBorder?: boolean;
    gapClass?: boolean
}

const CardsList = ({title, button, children, noBorder,gapClass}: CardsListProps) => {
    return (
        <div className={`cards-list ${noBorder ? 'no-border' : ''}`}>
            { title &&
                <div className={"title-wrapper d-flex align-items-center " + (gapClass ? "gap-6" : "justify-content-between")}>
                  <h2>{title}</h2>
                    {button}
                </div>
            }
            {children}
        </div>
    );
};

export default CardsList;
