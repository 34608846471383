import { BaseQueryFn, FetchArgs, fetchBaseQuery, retry } from '@reduxjs/toolkit/dist/query/react';
import { RootState } from '../store';
import { FetchBaseQueryError } from "@reduxjs/toolkit/query";
import { setClientOnboardingLevelTooSmall, setUserOnboardingLevelTooSmall } from "../data-access/auth-slice";
import { setMessage } from "../data-access/message-slice";

const baseQuery = retry(
  fetchBaseQuery({
    baseUrl: `${process.env.REACT_APP_API_URL}`,
    prepareHeaders: (headers, {getState}) => {
      const token = (getState() as RootState).auth.token

      if (token) {
        headers.append('authorization', `Bearer ${token}`)
      }
      headers.append("Accept", "application/json");

      return headers
    },
  }),
  {maxRetries: 0},
);

const baseQueryWithErrorCheck: BaseQueryFn<
  FetchArgs | string,
  unknown,
  FetchBaseQueryError
> = async (args, api, extraOptions) => {


  let result = await baseQuery(args, api, extraOptions);

  if (result.error && result.error.status !== 403) {
    const errorMsg = (result?.error.data as Error)?.message || "An error occurred!";
    if (errorMsg) {
      api.dispatch(setMessage({content: errorMsg, type: "error"}));
    }
  }

  if (
    result.error &&
    result.error.status === 403
  ) {
    if ((result.error.data as Error).message.includes("Client needs a higher onboarding level")) {
      api.dispatch(setClientOnboardingLevelTooSmall({clientOnboardingLevelTooSmall: true}));
    } else if ((result.error.data as Error).message.includes("User needs a higher onboarding level")) {
      api.dispatch(setUserOnboardingLevelTooSmall({userOnboardingLevelTooSmall: true}));
    }
  } else {
    api.dispatch(setClientOnboardingLevelTooSmall({clientOnboardingLevelTooSmall: false}));
    api.dispatch(setUserOnboardingLevelTooSmall({userOnboardingLevelTooSmall: false}));
  }

  return result;
};

export default baseQueryWithErrorCheck;